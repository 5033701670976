import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
// importar modulos vuex
import facturacion from "./modules/facturacion";
import movimientos from "./modules/movimientos";
import soportes from "./modules/soportes";
import notificaciones from "./modules/notificaciones";
import telcocorreos from "./modules/telcocorreos";
import pagos from "./modules/pagos";
import saldo from "./modules/saldo";
import transferencias from "./modules/transferencias";
// importar servicios
import { Utils } from "@/services/utils.js";
import { Clientes } from "@/services/clientes.js";

export const PASARELA_ID = process.env.VUE_APP_SISTEMA_ID;
Vue.use(Vuex);
export default new Vuex.Store({
  // Las propiedades del state son comunes a todos los componentes. Un cambio en alguna de estas propiedades
  // es susceptible de verse y accederse en cada componente
  state: {
    // indica el estado de la aplicacion
    mantenimiento: "",
    // datos de session (id del cliente - array de cuentas del cliente - id de la cuenta a la que ingresa)
    cliente_ID: "",
    cuenta_usuario: [],
    cuenta_ID: "",
    // datos del usuario que inicio sesion (id, email, y permisos -> decodificados en el token)
    isAutenticated: false,
    id: "", // user id
    email: "",
    permission: [],
    rol: [],
    expired: 0,
    api_key_prueba: "123123123123123",
    SISTEMA_ID: PASARELA_ID,
    CLIENTE_CUENTA_DATA: "",
    UserId:"",
  },

  mutations: {
    setisAutenticated(state, value) {
      state.isAutenticated = value;
    },
    setExpired(state, value) {
      state.expired = value;
    },
    setUsers(state, email) {
      state.email = email;
    },
    setUserId( state, value){
      state.UserId = value;
    },

    setID(state, id) {
      state.id = id;
    },
    setPermission(state, permission) {
      state.permission = permission;
    },
    setRol(state, rol) {
      state.rol = rol;
    },
    setCliente(state, cliente_ID) {
      state.cliente_ID = cliente_ID;
    },
    setClienteCuentaData(state, data) {
      state.CLIENTE_CUENTA_DATA = data;
    },
    setCuenta(state, cuenta_ID) {
      state.cuenta_ID = cuenta_ID;
    },
    setCuentaUsuarios(state, cuenta_usuario) {
      state.cuenta_usuario = cuenta_usuario;
    },
    setApiKey(state, value) {
      state.api_key_prueba = value;
    },
    setEstadoAplicacion(state, value) {
      state.mantenimiento = value;
    },
    logout(state) {
      // se inicializa los valores del state, se remueve el token y se redirecciona a view login
      localStorage.removeItem("token");
      localStorage.removeItem("refresh-token");
      state.isAutenticated = false;
      state.permission = [];
      state.cuenta_usuario = [];
      state.email = "";
      state.id = "";
      state.cliente_ID = "";
      state.cuenta_ID = "";
      state.expired = 0;
      // limpiar todos los estados
      router.push("/login");
    },
  },
  // ACCIONES
  actions: {
    /**
     * Cambiar pagos, transferencias y movimientos
     */
    getAccountChange({ dispatch }) {
      dispatch("saldo/getSaldo", null, { root: true });
      // dispatch("saldo/getSaldoSubcuentas", null, { root: true });
      dispatch("movimientos/getMovimientos", null, { root: true });
      dispatch("pagos/getPagos", null, { root: true });
      dispatch("transferencias/getDatosTransferencias", null, { root: true });
      dispatch("transferencias/getAllTransferencias", null, { root: true });
    },
    /**
     * Cambia los datos refereidos a un cliente
     * @param {*} client_id
     */
    async getStateChange({ commit }, client_id) {
      var data = new FormData();
      data.append("Id", client_id);
      data.append("CargarCuentas", true);
      data.append("CargarImpuestos", true);
      data.append("CargarRubros", true);
      data.append("CargarCuentaComision", true);
      data.append("CargarTiposPago", true);
      // data.append("CargarCuentasSubcuenta", true);



      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      await Clientes.getAllDataClienteId(data, config)
        .then((response) => {
          // Variables principales
          commit("setClienteCuentaData", response.data); // toda la data
          commit("setCliente", response.data.id); // el id del cliente
          commit("setCuentaUsuarios", response.data.cuenta); // array de cuentas del cliente
          //
          if (response.data.cuenta[0]) {
            commit("setCuenta", response.data.cuenta[0].id); // La primer cuenta del cliente por defecto
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    },
    /**
     * Obtiene toda la data referida a un usuario
     * @param {*} client_id
     */
    async getClientAllData({ commit, state }, client_id) {
      var data = new FormData();
      data.append("Id", client_id);
      data.append("CargarCuentas", true);
      data.append("CargarImpuestos", true);
      data.append("CargarRubros", true);
      data.append("CargarCuentaComision", true);
      data.append("CargarTiposPago", true);
      // data.append("CargarCuentasSubcuenta", true);


      const payloadclientecuenta = {
        CargarImpuestos: true,
        CargarCuentas: true,
        CargarRubros: true,
        CargarTiposPago: true,
        Id: client_id,
      };
    
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      if (state.rol.includes("psp.admin")) {
        await Clientes.getClienteCuentaLogin(payloadclientecuenta)
          .then((response) => {
            commit("setClienteCuentaData", response.data);
          })
          .catch((error) => {
            // console.log(data)
            // console.error(error);
          });
      } else {
        await Clientes.getAllDataClienteId(data, config)
          .then((response) => {
            commit("setClienteCuentaData", response.data);
          })
          .catch((error) => {
            // console.error(error);
          });
      }
    },
    

    async getEstadoAplicacion({ commit }) {
      await Utils.getEstadoApp()
        .then((response) => {
          if (response.data.status === false) {
            commit("setEstadoAplicacion", "sin valor");
          }
        })
        // el estado de mantenimiento dispara error http 503 servicio no disponible
        .catch((error) => {
          if (error.response.data.status === true) {
            commit("setEstadoAplicacion", error.response.data.fecha);
          }
        });
    },
  },
  getters: {
    // saber si es super administrador
    isSuperAdmin(state) {
      const rolName = "superadmin";
      const rols = state.rol.toString();
      return rols.includes(rolName);
    },
    // saber el nombre de la cuenta segun la cuenta id para ser mostrada al usuario
    getAccountNameById(state) {
      if (state.cuenta_usuario.length > 0) {
        var cuenta_actual = state.cuenta_usuario.filter(
          (cuenta) => cuenta.id == state.cuenta_ID
        );
        return cuenta_actual[0] ? cuenta_actual[0].cuenta : "Sin Cuenta";
      } else {
        return "";
      }
    },
    getCurrentAccountId: (state) => state.cuenta_ID,
    getCurrentClientId: (state) => state.cliente_ID,
    getCurrentClientName: (state) => state.CLIENTE_CUENTA_DATA.cliente,
    getCurrentClientEmail: (state) => state.email,
    getEnvironment: (state) => (state.SISTEMA_ID === "10" ? "dev" : "prod"),
    getUserId:(state) => state.UserId,
    CLIENTE_CUENTA_DATA: (state) => state.CLIENTE_CUENTA_DATA,

    permisos: (state) => state.permission,

    roles: (state) => state.rol,
  },
  modules: {
    facturacion,
    movimientos,
    pagos,
    saldo,
    transferencias,
    soportes,
    notificaciones,
    telcocorreos
  },
});
