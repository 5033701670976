<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="8">
        <v-hover>
          <v-alert
            dense
            elevation="3"
            icon="account_circle"
            outlined
            prominent
            text
            type="success"
            style="font-family: Montserrat, sans-serif; font-size: 1.1em;"
          >
            Cliente :
            <span class="font-weight-bold">{{
              $store.state.CLIENTE_CUENTA_DATA.cliente
            }}</span>
            - Cuenta :
            <span class="font-weight-bold">{{ getAccountNameById }}</span>
          </v-alert>
        </v-hover>
      </v-col>

      <v-col cols="6" md="4">
        <v-hover>
          <v-alert
            dense
            elevation="3"
            icon="paid"
            outlined
            prominent
            text
            type="success"
            style="font-family: Montserrat, sans-serif; font-size: 1.1em;"
          >
            Saldo Disponible:
            <span class="font-weight-bold" :class="getColor(saldo)">{{
              saldoCuenta(saldo)
            }}</span>
            <div
              v-if="mostrarCostoServicio && subcuentaPrincipal === 'principal'"
            >
              <!-- <small
                class="text-caption"
                style="font-family: Montserrat, sans-serif;"
              >
                (incluye costo servicio)
              </small> -->
            </div>
          </v-alert>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "Saldo",

  mounted() {
    this.chekearSiSaldoSubcuenta();
  },

  computed: {
    ...mapState("saldo", ["saldo", "subcuentas"]),
    ...mapGetters([
      "CLIENTE_CUENTA_DATA",
      "getAccountNameById",
      "getCurrentAccountId",
    ]),

    mostrarCostoServicio() {
      const currentAccount = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id == this.getCurrentAccountId
      );

      return currentAccount.aplica_costo_servicio;
    },
    subcuentaPrincipal() {
      const currentAccount = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id == this.getCurrentAccountId
      );
      return currentAccount.tipo_subcuenta;
    },
  },
  watch: {
    getCurrentAccountId() {
      this.chekearSiSaldoSubcuenta();
    },
  },

  methods: {
    ...mapActions("saldo", ["getSaldo"]),
    // ...mapActions("saldo", ["getSaldo", "getSaldoSubcuentas"]),

    chekearSiSaldoSubcuenta() {
      // const cuenta = this.$store.state.CLIENTE_CUENTA_DATA.cuenta.find(
      //   (cuenta) => cuenta.id === this.getCurrentAccountId
      // );

      // if (
      //   cuenta &&
      //   cuenta.split_cuentas === true &&
      //   cuenta.subcuentas_id &&
      //   cuenta.subcuentas_id.length > 0
      // ) {
      //   this.getSaldoSubcuentas();
      // } else {
      this.getSaldo();
      // }
    },

    getColor(value) {
      if (value >= 0) return "success--text-custom";
      else return "red--text";
    },

    saldoCuenta(saldo) {
      // return Number(num).toLocaleString('es-ar',{style: 'currency',currency: 'ARS',minimumFractionDigits: 2}
      return saldo.toLocaleString("es-ar", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
<style scoped>
.v-alert {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
  margin-right: 10px;
  padding: 16px;
  position: relative;
}
.v-alert--outlined {
  background: transparent !important;
  border: 0px;
}
</style>
