import { Saldo } from '../../services/saldo.js';
import { Refresh } from '../../services/refreshToken';
export default {
  namespaced: true,
  state: {
    saldo: 0,
    subcuentas:"",
  },
  mutations: {
    setSaldo(state, saldoResponse) {
      state.saldo = saldoResponse.data.total;
    },
    setSubcuentas(state, subcuenta) {
      state.subcuentas = subcuenta;
    },

  },
  actions: {

    //Funcion para Saldo de Subcuentas
    // async getSaldoSubcuentas({ commit,rootState }) {

    //   const subcuentasId = rootState.CLIENTE_CUENTA_DATA.cuenta.find(
    //     cuenta => cuenta.id === rootState.cuenta_ID && cuenta.split_cuentas === true
    //   )?.subcuentas_id || [];

    //   if (subcuentasId.length > 0) {
    //     commit('setSubcuentas', subcuentasId.join(',')) 
    //   } else {
    //     commit('setSubcuentas','');
    //   }
    
    //   const params = {
    //     SubCuentaIds: subcuentasId.join(','),
        
    //   };
      
    //   await Saldo.getSaldoSubCuenta({params})
    //     .then(response => {
    //       commit('setSaldo', response);
    //     }).catch(error => {
    //       var data = error.response.status;
    //       if (data == 403) {
    //         async () => {
    //           try {
    //             await Refresh.getRefreshToken()
    //               .then(response => {
    //                 //
    //               })

    //           } catch (error) {
    //             //
    //           }
    //         }
    //       }
    //     });
    // },

    // esta funcion recibe rootState (estado que contiene el clienteID del store/index)
    async getSaldo({ commit, rootState }) {
      const cuenta_id = rootState.cuenta_ID;
      const params = {
        cuenta_id: cuenta_id,
      }

      const subcuentasId = rootState.CLIENTE_CUENTA_DATA.cuenta.find(
        cuenta => cuenta.id === rootState.cuenta_ID && cuenta.split_cuentas === true
      )?.subcuentas_id || [];

      if (subcuentasId.length > 0) {
        commit('setSubcuentas', subcuentasId.join(',')) 
      } else {
        commit('setSubcuentas','');
      }

      await Saldo.getSaldoCuenta(params)
        .then(response => {
          commit('setSaldo', response);
        }).catch(error => {
          var data = error.response.status;
          if (data == 403) {
            async () => {
              try {
                await Refresh.getRefreshToken()
                  .then(response => {
                    //
                  })

              } catch (error) {
                //
              }
            }
          }
        });
    },
  },

}
