import apiPasarela from "../apiPasarela";

const getCuentasComision = async (params) => {
    const endpoint = "/administracion/cuenta-costo-servicio";
    const { data } = await apiPasarela.get(endpoint, {params});
    return data;
};

const postCuentasComision = async (payload) => {
    const endpoint = "/administracion/cuenta-costo-servicio";
    const { data } = await apiPasarela.post(endpoint, payload);
    return data;
};

const deleteCuentasComision = async (params) => {
    const endpoint = "/administracion/cuenta-costo-servicio";
    const { data } = await apiPasarela.delete(endpoint, {params});
    return data;
};

export default { getCuentasComision, postCuentasComision, deleteCuentasComision };
