<template>
  <div>
    <div style="background-color: white;">
      <!-- CONTAINER DISEÑO DE CARD FORM DE INICIO DE SESION -->
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xl-4 col-md-6 ml-auto mr-auto">
            <div class="separador_laptop">
              <div class="pl-5 pr-5" style="color: #C2D501;">
                <h1><strong>INICIAR SESIÓN</strong></h1>
              </div>
              <div v-if="!isMantenimiento" class="card card-profile">
                <div class="card-body">
                  <div class="card card-login">
                    <form class="form" @submit.prevent="iniciarSesion">
                      <p style="font-weight: 400; font-size: 20px">
                        ¡Reciba todos los medios de pagos con nuestra solución
                        para empresas y profesionales!
                      </p>
                      <br />
                      <div>
                        <span class="bmd-form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="material-icons">mail</i>
                              </span>
                            </div>
                            <input
                              class="form-control"
                              v-model="username"
                              placeholder="Correo electronico..."
                            />
                          </div>
                        </span>
                        <span class="bmd-form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text">
                                <i class="material-icons">lock_outline</i>
                              </span>
                            </div>
                            <input
                              :type="showPassword ? 'text' : 'password'"
                              class="form-control"
                              v-model="password"
                              placeholder="Contraseña..."
                            />
                            <v-icon
                              slot="append"
                              color="green"
                              @click="showPassword = !showPassword"
                            >
                              {{ !showPassword ? "mdi-eye" : "mdi-eye-off" }}
                            </v-icon>
                          </div>
                        </span>
                      </div>
                      <br />
                      <button class="btn btn-round gradient_color">
                        INICIAR SESIÓN
                      </button>

                      <div class="my-3">
                        <span
                          class="forgot-password"
                          @click="dialogForgot = true"
                          >¿Olvidaste tu contraseña?</span
                        >
                      </div>

                      <!-- mostrar mensaje de error de api autentication service -->
                      <div v-if="error" class="alert alert-danger">
                        <div class="container">
                          <div class="alert-icon">
                            <i class="material-icons">error_outline</i>
                          </div>
                          <b v-html="msg"></b>
                        </div>
                      </div>
                      <!-- mostrar mensaje de error de api autentication service -->
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ZONA DE MODAL ELEGIR CUENTA y  OTROS  -->
      <v-app>
        <v-main>
          <v-container fluid>
            <!-- DIALOG MODAL CLIENTE -->
            <v-row justify="center">
              <v-dialog
                persistent
                v-model="dialogCliente"
                scrollable
                max-width="400px"
              >
                <v-card>
                  <v-card-title class="text-h6 justify-center"
                    >Seleccione un Cliente
                  </v-card-title>
                  <v-divider></v-divider>
                  <div>
                    <v-text-field
                      v-model="searchCli"
                      append-icon="mdi-magnify"
                      class="pt-3 px-3"
                      label="Buscar"
                      clearable
                      solo
                      hide-details
                      dense
                      @click:clear="limpiarBusqueda"
                    ></v-text-field>
                  </div>

                  <v-card-text style="height: 250px">
                    <v-radio-group v-model="id_radio_clientes" column>
                      <template v-if="filteredClientes.length > 0">
                        <!-- Lista de clientes filtrados -->
                        <v-radio
                          class="pb-5"
                          v-for="dat in filteredClientes"
                          :key="dat.id"
                          :label="dat.cliente"
                          :value="dat.id"
                        ></v-radio>
                      </template>
                      <template v-else>
                        <!-- Mensaje cuando no hay coincidencias -->
                        <div>
                          <p class="text-gray">No se encontró cliente</p>
                        </div>
                      </template>
                    </v-radio-group>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn color="blue darken-1" text @click="cerrar">
                      Cerrar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmarCliente()"
                    >
                      seleccionar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

            <!-- DIALOG MODAL CUENTA -->
            <v-row justify="center">
              <v-dialog
                persistent
                v-model="dialog"
                scrollable
                max-width="400px"
                v-if="cuentas.length > 1"
              >
                <v-card>
                  <v-card-title class="text-h6 justify-center"
                    >Seleccione una cuenta
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text style="height: 200px">
                    <v-radio-group
                      v-model="dialogm1"
                      column
                      v-for="dat in cuentas"
                      v-bind:key="dat.id"
                    >
                      <v-radio :label="dat.cuenta" :value="dat.id"></v-radio>
                    </v-radio-group>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="justify-center">
                    <v-btn color="blue darken-1" text @click="cerrar">
                      Cerrar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmar_inicio_sesion()"
                    >
                      Ingresar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>

            <!--  LOADING: verificando datos del usuario (login service) -->
            <div class="text-center">
              <v-dialog v-model="dialog1" hide-overlay persistent width="300">
                <v-card color="primary" dark>
                  <v-card-text>
                    Verificando datos de sesión
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>

            <!-- DIALOG MODAL DE SERVICIO EN MANTENIMEINTO -->
            <div class="text-center">
              <v-dialog
                v-model="isMantenimiento"
                persistent
                scrollable
                max-width="500px"
              >
                <v-card>
                  <v-card-title class="text-h6 justify-center">
                    Servicio no Disponible
                  </v-card-title>
                  <v-card-text>
                    <div
                      class="
                        d-flex
                        flex-column
                        justify-space-between
                        align-center
                      "
                    >
                      <v-img
                        max-height="150"
                        max-width="150"
                        src="/assets/img/mantenimiento.png"
                      ></v-img>
                    </div>
                    <v-alert
                      outlined
                      type="warning"
                      dark
                      icon="cloud_off"
                      prominent
                      border="left"
                      transition="scale-transition"
                    >
                      El servicio estará en mantenimiento hasta el
                      {{ formatFechaMantenimiento }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <router-link to="/">
                      <v-btn color="blue darken-1" text> Continuar </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-container>
        </v-main>
      </v-app>
      <!-- FIN MODAL ELEGIR CUENTA -->
    </div>
    <ForgotPassword :showDialog.sync="dialogForgot" :validEmail="validEmail" />
    <Footer />
  </div>
</template>
<script>
import jwt_decode from "jwt-decode";
import { Login } from "../services/login.js";
import { Clientes } from "../services/clientes.js";
import moment from "moment";
import { mapState } from "vuex";
import ForgotPassword from "../components/Accesos/ForgotPassword.vue";
import Footer from "@/views/Layouts/Footer.vue";

export default {
  name: "Login",
  components: {
    Footer,
    ForgotPassword,
  },
  data() {
    return {
      id_radio_clientes: 0,
      clientes: [],
      dialogCliente: false,
      dialog1: false, // loading verificando datos del usuario
      dialog2: false,

      // verificacion: consultar si el usuario esta vinculado a algun cliente/cuenta
      token: "",
      refreshtoken: "",
      UserId: "",
      cliente_id: "",

      //  cuentas del usuario: el usuario debera seleccionar una cuenta de usuario
      dialogm1: "",
      dialog: false,
      cuentas: [],
      // parametros para login service
      username: "",
      password: "",
      sistema_id: this.$store.state.SISTEMA_ID,

      //variable error en false oculta
      error: false,
      msg: "",
      cliente_cuenta_data: {},

      //mostrar dialogo de restablecimiento de contraseña
      dialogForgot: false,

      showPassword: false,
      // Para Buscar Clientes
      searchCli: "",
    };
  },
  mounted() {
    this.$store.dispatch("getEstadoAplicacion");
  },
  computed: {
    ...mapState(["mantenimiento"]),

    filteredClientes() {
      return this.searchCli
        ? this.clientes?.filter((cliente) =>
            cliente?.cliente
              ?.toLowerCase()
              .includes(this.searchCli?.toLowerCase())
          )
        : this.clientes;
    },

    // En base al token obtenido se verifica el rol del usuario que intenta acceder
    permisos() {
      // decodifico el token obtenido
      var decoded = jwt_decode(this.token);
      if (!decoded.roles) return this.$toastr.e("Usuario sin roles asignados");
      if (!decoded.permisos)
        return this.$toastr.e("Usuario sin permisos asignados");

      return decoded.permisos;
    },
    // La porpiedad mantenimiento es un store binding. Puede estar vacia o con valor "sin valor". Ambos casos implican app activa
    isMantenimiento() {
      if (this.mantenimiento == "sin valor" || !this.mantenimiento) {
        return false; // la app esta activa
      }
      return true; // la app esta en mantenimiento
    },
    formatFechaMantenimiento() {
      if (this.mantenimiento != "" && this.mantenimiento != "sin valor") {
        return (
          moment.utc(this.mantenimiento).format("DD-MM-YYYY HH:mm") + " hs"
        );
      }
      return "";
    },
  },
  methods: {
    limpiarBusqueda() {
      this.searchCli = ""; // Borra el término de búsqueda
    },
    confirmarCliente() {
      if (this.id_radio_clientes != "") {
        var id_cliente = this.id_radio_clientes;
        this.obtenerClienteCuentas(id_cliente);
        this.dialogCliente = false;
        this.searchCli = "";
      } else {
        this.$toastr.e("Debe seleccionar un Cliente");
      }
    },

    async iniciarSesion() {
      var payload = {
        username: this.username,
        password: this.password,
        sistema_id: this.sistema_id,
      };
      // (se coloca en falso la variable error al enviar una nueva peticion)
      this.error = false;

      if (this.checkform()) {
        this.dialog1 = true;
        await Login.authenticator(payload)
          .then(({ data }) => {
            if (data && data.activo != "") {
              // Respuesta 200 OK Usuario Bloqueado por falta de confirmacion de email de verificacion
              this.error = true;
              this.msg = data.activo;
              this.$toastr.e(data.activo);
              return;
            }

            // */ se decodifica el token para obtener el id del usuario */
            var decoded = jwt_decode(data.token);
            this.$store.commit("setUserId", decoded.id);
            // */ se guardar los datos necesarios para enviar peticion */
            this.token = data.token;
            this.refreshtoken = data.refreshtoken;
            this.UserId = decoded.id;

            // */ se guarda token y refreshtoken en local-storage */
            localStorage.setItem("token", this.token);
            localStorage.setItem("refreshtoken", this.refreshtoken);
          })
          .catch((error) => {
            var data = error.response.data;
            //mostrar mensaje de error en caso que la variable sea true(tenga datos)
            this.error = true;
            this.msg = data.message;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.dialog1 = false)); // ocultar el loader de verificando

        // Si la primera peticion fue exitosa, se envia la segunda
        if (!this.error) {
          // Si es superadmin mostrar dialogo de elegir cliente
          this.permisos.some((p) => p === "psp.admin.clientes")
            ? this.obtenerClientes()
            : this.obtenerClienteCuentas();
        }
      } // End this.checkform()
    },
    cerrar() {
      this.limpiar_campos();
    },
    // Obtener las cuentas de un cliente o un usuario y abrir el dialogo de elegir cuenta
    obtenerClienteCuentas(p_cliente_id = "") {
      // EL que intenta iniciar sesion es superadmin
      if (p_cliente_id != "") {
        var data = new FormData();
        data.append("Id", p_cliente_id);
        data.append("CargarCuentas", true);
        data.append("CargarImpuestos", false);
        data.append("CargarRubros", true);
        data.append("CargarCuentaComision", false);
        data.append("CargarTiposPago", true);
        // data.append("CargarCuentasSubcuenta", true);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        Clientes.getAllDataClienteId(data, config)
          .then((response) => {
            this.cuentas = response.data.cuenta;
            this.cliente_id = response.data.id;
            this.$store.commit("setClienteCuentaData", response.data);

            //Comprueba si el usuario tiene más de una cuenta
            if (this.cuentas.length > 1) {
              this.dialog = true; // Se abre el dialogo de seleccionar cuenta
            } else {
              this.dialogm1 = this.cuentas[0].id;
              this.confirmar_inicio_sesion();
            }
          })
          // el estado de mantenimiento dispara error http 503 servicio no disponible
          .catch((error) => {
            this.$toastr.e("Error al consultar los datos del cliente");
          });
      }

      if (p_cliente_id == "") {
        const payloadclientecuenta = {
          CargarImpuestos: false,
          CargarCuentas: true,
          CargarRubros: false,
          CargarTiposPago: true,
          UserId: this.UserId,
        };
        Clientes.getClienteCuentaLogin(payloadclientecuenta)
          .then((response) => {
            if (response.data.cuenta == null) {
              this.$toastr.e("El cliente no tiene asociada ninguna cuenta");
            } else {
              //  se carga las cuentas en la que esta asociado el usuario
              this.cuentas = response.data.cuenta;
              this.cliente_id = response.data.id;
              //Para guardar los Datos del cliente en el store
              this.$store.commit("setClienteCuentaData", response.data);
              this.$store.dispatch("getClientAllData", response.data.id);

              //Comprueba si el usuario tiene más de una cuenta
              if (this.cuentas.length > 1) {
                this.dialog = true; // Se abre el dialogo de seleccionar cuenta
              } else {
                this.dialogm1 = this.cuentas[0].id;
                this.confirmar_inicio_sesion();
              }
            }
          })
          .catch((error) => {
            // El response que viene puede ser undefined
            if (error.response) {
              var data = error.response.data;
              this.error = true;
              this.msg = data.message;
              this.$toastr.e(this.msg);
            } else {
              this.error = true;
              this.msg =
                "Error al comunicarse con el servidor de autenticación";
              this.$toastr.e(this.msg);
            }
          });
      }
    },
    // Obtiene los clientes y abre el dialogo de elegir clientes
    obtenerClientes() {
      const params = {
        CargarImpuestos: false,
        CargarCuentas: true,
        CargarRubros: true,
        // CargarCuentasSubcuenta: true,
      };

      Clientes.getClientes(params)
        .then((response) => {
          if (response.data.data == null) {
            this.$toastr.e("No existen clientes");
          } else {
            this.clientes = response.data.data;
            this.dialogCliente = true; // Se abre el dialogo de seleccionar cliente
          }
        })
        .catch((error) => {
          // El response que viene puede ser undefined
          if (error.response) {
            var data = error.response.data;
            this.error = true;
            this.msg = data.message;
            this.$toastr.e(this.msg);
          } else {
            this.error = true;
            this.msg = "Error al comunicarse con el servidor de autenticación";
            this.$toastr.e(this.msg);
          }
        });
    },

    // Este metodo se ejecuta cuando el usuario escoge una cuenta y da click en ingresar
    // Se setean las variables de sesion, permisos y otras
    confirmar_inicio_sesion() {
      if (this.dialogm1 != "") {
        //  this.axios.defaults.headers.common["Authorization"] =
        //   "Bearer" + this.token;

        //  decodificar token para obtener los datos de sesion:  permisos, cuentas , id , tiempo de expiracion etc
        var decoded = jwt_decode(this.token);

        // el usuario pasa a estar autentificado
        this.$store.commit("setisAutenticated", true);
        this.$store.commit("setExpired", 3600);
        // datos del usuario
        this.$store.commit("setUsers", decoded.user);
        this.$store.commit("setID", decoded.id);
        this.$store.commit("setCliente", this.cliente_id);
        this.$store.commit("setCuenta", this.dialogm1);
        this.$store.commit("setCuentaUsuarios", this.cuentas);

        // permisos del usuario
        this.$store.commit("setPermission", decoded.permisos);
        // Setear en el state del Store el rol del usuario
        this.$store.commit("setRol", decoded.roles);

        this.$router.push("/pagos");
        this.limpiar_campos();
      } else {
        this.$toastr.i("Debe seleccionar una cuenta");
      }
    },

    // limpiar_campos
    limpiar_campos() {
      this.dialog = false;
      this.searchCli = "";
      this.username = "";
      this.password = "";
      this.token = "";
      this.UserId = "";
      this.dialogm1 = "";
      this.cliente_id = "";
      this.dialogCliente = false;
      this.clientes = [];
    },

    checkform() {
      this.error = false;
      if (!this.username && !this.password) {
        this.error = true;
        this.msg = "Los campos email y password son obligatorios";
        return false;
      } else if (!this.password) {
        this.error = true;
        this.msg = "La contraseña debe ser válida";
        return false;
      } else if (!this.validEmail(this.username)) {
        this.error = true;
        this.msg = "El correo electrónico debe ser válido";
        return false;
      } else {
        return true;
      }
    },
    validEmail: function(username) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(username);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../sass/main.scss";
body {
  font-family: "Montserrat", sans-serif;
}
.separador_laptop {
  max-width: 600px !important;
}
@media (min-width: 280px) and (max-width: 1260px) {
  h1 {
    font-weight: 700 !important;
    text-align: center;
    border-bottom: 5px solid transparent !important;
    border-image: linear-gradient(
        to right,
        #00b1eb 0%,
        #1db6c8 50%,
        #c2d501 100%
      )
      1;
    margin-bottom: 2% !important;
    font-size: 2em !important;
  }
}
h1 {
  font-weight: 900;
  text-align: center;
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(to right, #00b1eb 0%, #1db6c8 50%, #c2d501 100%)
    1;
  margin-bottom: 10%;
  font-size: 3em;
}
.btn-round {
  border-radius: 7px;
}
.card {
  border-radius: 42px;
  padding: 0.5rem 7px;
}
.card-login {
  margin-bottom: 0px !important;
  margin-top: 0;
  padding: 5%;
  background: linear-gradient(#fff 0 0) padding-box,
    /*this is your grey background*/
      linear-gradient(90deg, #c2d501 0%, #1db6c8 51.56%, #00b1eb 100%)
      border-box;
  border: 5px solid transparent;
  border-radius: 42px;
}

.form-control {
  padding-left: 10px !important;
}

.theme--light.v-application {
  background: #ffffff00;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 1250px) {
  .separador_laptop {
    margin-top: 5em;
  }
}
.gradient_color {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  font-weight: 900;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: linear-gradient(270deg, #c2d501 0%, #1db6c8 50.52%, #00b1eb 100%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
}
.gradient_color:hover {
  background-image: linear-gradient(
    to left,
    #c2d501 0%,
    #1db6c8 50.52%,
    #00b1eb 100%
  );
  color: #000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 8px 10px 12px;
}

.forgot-password {
  cursor: pointer;
  font-weight: 800;
  color: #444;
}

.forgot-password:hover {
  color: #000;
}
</style>
