<template>
    <transition name="slide-fade" mode="out-in">
        <div id="chatbotTelCo" :data-token="token" style="z-index: 99999999; min-width: 80px;"></div>
    </transition>
</template>

<script>

export default {
    name: 'chatbotTelCo',
    props: {
        token: {
            default: '',
            type: String,
        },
    },    
    async mounted() {
        this.loadCss();               
        setTimeout(() => {
            this.loadJs();
        }, 5000);           
    },    
    methods: {        
        loadCss() {
            const link = document.createElement('link');
            link.rel = "stylesheet";
            link.href = "https://dev-api-chatbot.telco.com.ar/v1/get-file/css";
            document.head.appendChild(link);
        },
        loadJs() {
            const script = document.createElement('script');
            script.src = "https://dev-api-chatbot.telco.com.ar/v1/get-file/js";
            document.head.appendChild(script);
        }
    }
}
</script>
