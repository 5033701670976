<template>
  <div class="home-container">
    <!-- si el usuario no esta logueado  -->
    <div v-if="!$store.state.isAutenticated">
      <template>
        <!-- <NewNav /> -->
        <NewNav2 :bgNav="currentNavColor" :textNav="currentTextNav" />
        <v-slide-x-transition mode="out-in">
          <router-view />
        </v-slide-x-transition>
      </template>
    </div>

    <!-- si el usuario  esta logueado  -->
    <div v-if="$store.state.isAutenticated">
      <template>
        <div class="wrapper">
          <div
            class="sidebar"
            data-color="purple"
            data-background-color="white"
            data-image=""
            style="z-index: 0;"
          >
            <div class="logo">
              <img
                src="/assets/img/w.png"
                alt="TelCo Pasarela de Pagos"
                id="imagen_logo"
              />
            </div>

            <Expired />

            <div
              class="sidebar-wrapper ps-container ps-theme-default d-flex flex-column"
              data-ps-id="c9bd6856-2b56-3993-3eac-497ad2eabc62"
              style="background-color: black; justify-content: space-between"
            >
              <Sidebar />
              <p class="text-center derechos-reservados">By Telco</p>
            </div>
          </div>
          <div
            class="main-panel ps-container ps-theme-default ps-active-y"
            data-ps-id="63c1fe79-4d9d-fdd2-3114-ed0f7a76544c"
          >
            <!-- Navbar -->
            <Nav />

            <!-- efecto de transicion  -->
            <!-- <v-slide-y-transition mode="out-in">
              <router-view />
            </v-slide-y-transition> -->

            <transition name="slide-fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </template>
    </div>
    <chatbot :token="token" v-if="$store.state.isAutenticated && hasPermission('psp.view.chatbot')"/>
  </div>
</template>
<script>
import Nav from "@/views/Layouts/Nav.vue";
import chatbot from "@/components/Chatbot/chatbot.vue";
// import NewNav from "@/views/Layouts/NewNav.vue";
import NewNav2 from "@/views/Layouts/NewNav2.vue";
import Sidebar from "@/views/Layouts/Sidebar.vue";
//import Ayuda from "@/views/Ayuda.vue";
import Expired from "@/components/Expired/Token.vue";
//import Footer from "@/views/Layouts/Footer.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Nav,
    Sidebar,
    // Ayuda,
    Expired,
    NewNav2,
    chatbot,
    // NewNav,
    //   Footer,
  },
  data: () => ({
    token: process.env.VUE_APP_TOKENOLLAMA,
  }),

  computed: {
    ...mapGetters(["permisos"]),
    currentNavColor() {
      if (this.$route.fullPath === "/") return "#00b1eb";

      return "#fff";
    },
    currentTextNav() {
      if (this.$route.fullPath === "/") return "#fff";

      return "#000";
    },
  },
  methods: {
    hasPermission(permissionName) {
        return this.permisos.includes(permissionName);
    },
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.home-container {
  overflow-x: hidden;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #01050a;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
a:link {
  color: rgb(240, 240, 240);
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: rgba(232, 232, 233, 0.863);
  background-color: transparent;
  text-decoration: none;
}
a:active {
  color: rgb(241, 241, 239);
  background-color: transparent;
  text-decoration: underline;
}
.color-black a:visited {
  color: rgba(6, 6, 7, 0.863);
  background-color: transparent;
  text-decoration: none;
}
#buscar::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
/* funciones de espera y duración diferentes.      */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(800px);
  opacity: 0;
}
#imagen_logo {
  max-width: 50%;
  margin-left: 25%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.derechos-reservados {
  color: #fff;
}

.wrapper {
  overflow: hidden;
  min-height: 100vh !important;
  max-height: 100%;
}

.sidebar-wrapper {
  height: 84% !important;
}

body {
  overflow: auto;
}
</style>
