<template>
  <div class="content">
    <v-app>
      <v-main>
        <v-container fluid>
          <Saldo />
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Movimientos</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                shaped
                transition="fade-transition"
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section>
              <v-card>
                <!-- filtros table  -->
                <v-expansion-panels v-model="panel">
                  <v-expansion-panel :key="0">
                    <v-expansion-panel-header
                      class="outline_none"
                      disable-icon-rotate
                    >
                      <template v-slot:actions>
                        <span class="primary--text text-subtitle-2 mt-0"
                          >Filtros</span
                        >
                        <v-icon size="33" color="success"> search </v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- 1 fila de filtros fechas -->
                      <v-row class="pt-0 mx-5">
                        <v-col cols="6" md="4">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="ml-5"
                                label="Fecha movimiento desde"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                persistent-hint
                                :value="FormatDateDesde"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date1"
                              locale="es-ar"
                              no-title
                              scrollable
                              :max="new Date().toISOString().substr(0, 10)"
                              @change="inicio(date1)"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" md="4">
                          <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="ml-2"
                                :value="FormatDateHasta"
                                label="Fecha movimiento hasta"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                persistent-hint
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date2"
                              locale="es-ar"
                              no-title
                              scrollable
                              :min="fechaDesde"
                              :max="new Date().toISOString().substr(0, 10)"
                              @change="fin(date2)"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col
                          cols="6"
                          md="4"
                          v-if="subcuentaPrincipal !== 'principal'"
                        >
                          <!-- <span class="mr-3 font-bold">Exportar:</span> -->
                          <v-tooltip top color="success">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :loading="loadingExcel"
                                icon
                                class="bg-success white--text"
                                large
                                v-bind="attrs"
                                v-on="on"
                                @click="getMovimientoSaldo()"
                              >
                                <v-icon> mdi-microsoft-excel </v-icon>
                              </v-btn>
                            </template>
                            <span>Exportar Excel</span>
                          </v-tooltip>

                          <v-tooltip top color="error">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :loading="loadingPDF"
                                icon
                                large
                                class="ma-3 bg-danger white--text"
                                v-bind="attrs"
                                v-on="on"
                                @click="getMovimientoSaldo(true)"
                              >
                                <v-icon dark> mdi-file-pdf-box </v-icon>
                              </v-btn>
                            </template>
                            <span>Exportar PDF</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>

                      <!-- 2 fila de filtros concepto y referencia-->
                      <v-row class="pt-0 mx-10">
                        <!-- filtro por concepto de pago  -->
                        <v-col cols="6" md="4">
                          <v-text-field
                            v-model="searchByConcepto"
                            label="Concepto"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- filtro por referencia de pago -->
                        <v-col cols="6" md="4">
                          <v-text-field
                            v-model="searchByReferencia"
                            label="Referencia"
                            single-line
                            hide-details
                          >
                          </v-text-field>
                        </v-col>

                        <!-- channels/canales o medios de pago -->
                        <v-col cols="6" md="4">
                          <!-- <v-select
                                :items="Canales"
                                 v-model="medios_pago"
                                 item-text="Nombre"
                                 :item-value="'Id'"
                                label="Canales de pago"
                              ></v-select> -->

                          <v-autocomplete
                            v-model="medios_pago"
                            item-text="Nombre"
                            :item-value="'Id'"
                            :items="Canales"
                            :label="`Canales de pago`"
                          >
                            <template v-slot:append-outer v-if="medio_pago > 0">
                              <v-slide-x-reverse-transition mode="out-in">
                                <v-icon
                                  :key="`icon-${isEditingCanal}`"
                                  :color="'red'"
                                  @click="limpiar_filtro_canales()"
                                  >mdi-delete_sweep</v-icon
                                >
                              </v-slide-x-reverse-transition>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- end filtros table  -->

                <!-- Modal Detalle de Movimiento-->
                <v-dialog v-model="dialog" width="1000">
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Detalle de Movimiento
                      <v-divider class="mx-5" vertical /> Pago_ID:
                      <span class="font-weight-bold ml-3">
                        {{ itemDetallesMov[0].pago_id }}
                      </span>
                    </v-card-title>

                    <v-card-text>
                      <v-data-table
                        :items="itemDetallesMov"
                        :headers="headersDetalleMov"
                        no-data-text="Sin registros
                        para mostrar"
                        hide-default-footer
                      >
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <div id="_scrollableContainer">
                  <div id="dummy"></div>
                </div>

                <div id="pdf-preview"></div>

                <div id="_contentContainer">
                  <div class="custom-content">
                    <v-data-table
                      :search="searchByConcepto"
                      :headers="headers"
                      :items="movimientos"
                      :items-per-page="rowsPerPage"
                      :sort-by="['movimiento_created_at']"
                      :sort-desc="[true]"
                      hide-default-footer
                      class="trow custom-table"
                      :loading="cargando"
                      loading-text="Cargando... Espere por favor"
                      no-results-text="No se encontraron registros"
                      no-data-text="No se encontraron registros"
                      ref="mi_tabla"
                      item-key="id"
                    >
                      <!-- FECHA MOVIMIENTO -->
                      <template
                        v-slot:[`item.movimiento_created_at`]="{ item }"
                      >
                        <div class="custom-table">
                          {{ formatFecha(item.movimiento_created_at) }}
                        </div>
                      </template>

                      <!-- Concepto -->
                      <template v-slot:[`item.pago_tipo`]="{ item }">
                        <div class="custom-table" style="margin:-9px">
                          {{ item.pago_tipo }}
                        </div>
                      </template>

                      <!-- Tipo de Pago -->
                      <template v-slot:[`item.channels`]="{ item }">
                        <div class="custom-table" style="margin:-5px">
                          {{ item.channels }}
                        </div>
                      </template>

                      <!-- PAGO ID -->
                      <template v-slot:[`item.pago_id`]="{ item }">
                        <div class="custom-table">
                          <span>{{ item.pago_id }}</span>
                        </div>
                      </template>

                      <!-- Referencia de Pago -->
                      <template v-slot:[`item.external_reference`]="{ item }">
                        <v-list-item-text
                          text
                          @click="showDetails(item)"
                          style="text-decoration: underline;"
                          class="custom-table"
                        >
                          {{ item.external_reference }}
                        </v-list-item-text>
                      </template>

                      <!-- CONCEPTO, REFERENCIA DE PAGO, TIPO DE PAGO -->

                      <!-- IMPORTE SP -->
                      <template v-slot:[`item.montosp`]="{ item }">
                        <span
                          :class="getColor(item.montosp)"
                          class="custom-table"
                        >
                          {{ saldoMovimiento(item.montosp) }}
                        </span>
                      </template>

                      <!-- IMPORTE PAGADO -->
                      <template v-slot:[`item.montopagado`]="{ item }">
                        <span
                          :class="getColor(item.montopagado)"
                          class="custom-table"
                        >
                          {{ saldoMovimiento(item.montopagado) }}</span
                        >
                      </template>

                      <template
                        v-slot:[`item.retenciones.MontoTotal`]="{ item }"
                      >
                        <div
                          v-if="
                            item.retenciones.RetGanancias !== 0 ||
                              item.retenciones.RetIVA !== 0 ||
                              item.retenciones.RetIIBB !== 0
                          "
                        >
                          <v-tooltip color="primary" right>
                            <template v-slot:activator="{ on }">
                              <span
                                v-on="on"
                                :class="getColor(item.retenciones.MontoTotal)"
                                class="custom-table"
                              >
                                {{
                                  saldoMovimiento(item.retenciones.MontoTotal)
                                }}
                              </span>
                            </template>

                            <span
                              style="color: white; font-weight: bold"
                              v-show="item.retenciones.RetGanancias !== 0"
                              class="custom-table"
                            >
                              Ret Ganancias:
                              {{
                                saldoMovimiento(item.retenciones.RetGanancias)
                              }}
                            </span>
                            <span
                              style="color: white; font-weight: bold"
                              v-show="item.retenciones.RetIVA !== 0"
                              class="custom-table"
                            >
                              Ret IVA:
                              {{ saldoMovimiento(item.retenciones.RetIVA) }}
                            </span>
                            <br />
                            <span
                              style="color: white; font-weight: bold"
                              v-show="item.retenciones.RetIIBB !== 0"
                              class="custom-table"
                            >
                              Ret IIBB:
                              {{ saldoMovimiento(item.retenciones.RetIIBB) }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <span
                            :class="getColor(item.retenciones.MontoTotal)"
                            class="custom-table"
                          >
                            {{
                              item.retenciones.MontoTotal == 0
                                ? "-"
                                : saldoMovimiento(item.retenciones.MontoTotal)
                            }}
                          </span>
                        </div>
                      </template>

                      <!-- COMISION -->
                      <template v-slot:[`item.comision`]="{ item }">
                        <!-- COMENTANDO TOOLTIP
                    <div v-if="item.comision.detalle != null">
                      <v-tooltip color="primary" right>
                        <template v-slot:activator="{ on }">
                          <span
                            v-on="on"
                            :class="getColor(item.comision.total)"
                            >{{ saldoMovimiento(item.comision.total) }}</span
                          >
                        </template>
                        <span
                          v-for="val in item.comision.detalle"
                          :key="val.nombre"
                        >
                          <v-list-item-content>
                            <v-list-item-title> </v-list-item-title>
                            <v-list-item-subtitle
                              style="color: white; font-weight: bold"
                              >{{ val.nombre }}
                              <v-icon small style="color: white"
                                >attach_money</v-icon
                              >{{ saldoImpuestosComision(val.monto) }}
                              <v-icon small style="color: white"
                                >percent</v-icon
                              >
                              {{ val.porcentaje }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </span>
                      </v-tooltip>
                    </div>-->
                        <div v-if="item.comision.detalle != null">
                          <span
                            :class="getColor(item.comision.total)"
                            class="custom-table"
                            >{{ saldoMovimiento(item.comision.total) }}</span
                          >
                        </div>
                        <div v-else>
                          <span :class="getColor(item.comision.total)">
                            -
                          </span>
                        </div>
                      </template>

                      <!-- IMPUESTO -->
                      <template v-slot:[`item.impuestos`]="{ item }">
                        <div v-if="item.impuestos.detalle != null">
                          <!--<v-tooltip color="primary" right>-->
                          <template>
                            <span
                              :class="getColor(item.impuestos.total)"
                              class="custom-table"
                            >
                              {{ saldoMovimiento(item.impuestos.total) }}
                            </span>
                          </template>
                          <!--
                        <span
                          v-for="val in item.impuestos.detalle"
                          :key="val.nombre"
                        >
                          <v-list-item-content>
                             <v-list-item-title></v-list-item-title> 
                            <v-list-item-subtitle
                              style="color: white; font-weight: bold"
                            >
                              {{ val.nombre }}
                              {{ val.porcentaje * 100 }}
                              <v-icon small style="color: white">
                                percent
                              </v-icon>
                              <v-icon
                                small
                                style="color: white; font-weight: bold"
                              >
                                attach_money
                              </v-icon>
                              {{ saldoImpuestosComision(val.monto) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </span>
                      </v-tooltip>-->
                        </div>
                        <div v-else>
                          <span :class="getColor(item.impuestos.total)">
                            -
                          </span>
                        </div>
                      </template>

                      <!-- COSTO SERVICIO -->
                      <template v-slot:[`item.costo_servicio`]="{ item }">
                        <template>
                          <span
                            class="custom-table"
                            :class="getColor(item.monto)"
                          >
                            {{ saldoMovimiento(item.costo_servicio) }}
                          </span>
                        </template>
                      </template>

                      <!-- IMPORTE NETO -->
                      <template v-slot:[`item.monto`]="{ item }">
                        <div v-if="item.monto < 0">
                          <v-tooltip color="red lighten-1" right>
                            <template v-slot:activator="{ on }">
                              <span
                                v-on="on"
                                :class="getColor(item.monto)"
                                class="custom-table"
                              >
                                {{ saldoMovimiento(item.monto) }}
                              </span>
                            </template>

                            <span style="color: white; font-weight: bold">
                              Monto Transferido
                            </span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <span
                            :class="getColor(item.monto)"
                            class="custom-table"
                          >
                            {{ saldoMovimiento(item.monto) }}
                          </span>
                        </div>
                      </template>

                      <!-- Monto de Subcuentas -->
                      <template
                        v-slot:[`item.movimiento_subcuentas[0].monto`]="{
                          item,
                        }"
                      >
                        <span
                          v-if="
                            item.movimiento_subcuentas &&
                              item.movimiento_subcuentas.length > 0
                          "
                        >
                          <div v-if="item.movimiento_subcuentas[0].monto < 0">
                            <v-tooltip color="red lighten-1" right>
                              <template v-slot:activator="{ on }">
                                <span
                                  v-on="on"
                                  :class="
                                    getColor(
                                      item.movimiento_subcuentas[0].monto
                                    )
                                  "
                                  class="custom-table"
                                >
                                  {{
                                    saldoMovimiento(
                                      item.movimiento_subcuentas[0].monto
                                    )
                                  }}
                                </span>
                              </template>

                              <span style="color: white; font-weight: bold">
                                Monto Transferido
                              </span>
                            </v-tooltip>
                          </div>
                          <div v-else>
                            <span
                              :class="
                                getColor(item.movimiento_subcuentas[0].monto)
                              "
                              class="custom-table"
                            >
                              {{
                                saldoMovimiento(
                                  item.movimiento_subcuentas[0].monto
                                )
                              }}
                            </span>
                          </div>
                        </span>
                        <span v-else>
                          <div>
                            <span
                              :class="
                                getColor(item.movimiento_subcuentas[0].monto)
                              "
                              class="custom-table"
                            >
                              {{
                                saldoMovimiento(
                                  item.movimiento_subcuentas[0].monto
                                )
                              }}
                            </span>
                          </div>
                        </span>
                      </template>

                      <!-- Porcentaje aplicado de subcuentas -->
                      <template
                        v-slot:[`item.movimiento_subcuentas[0].porcentaje_aplicado`]="{
                          item,
                        }"
                      >
                        <span
                          v-if="
                            item.movimiento_subcuentas &&
                              item.movimiento_subcuentas.length > 0
                          "
                        >
                          {{
                            item.movimiento_subcuentas[0].porcentaje_aplicado
                          }}
                          %
                        </span>
                        <span v-else>
                          -
                        </span>
                      </template>

                      <!-- Monto costo servicio de Subcuentas -->
                      <template
                        v-slot:[`item.movimiento_subcuentas[1].monto`]="{
                          item,
                        }"
                      >
                        <span
                          v-if="
                            item.movimiento_subcuentas &&
                              item.movimiento_subcuentas.length > 1
                          "
                          :class="getColor(item.movimiento_subcuentas[1].monto)"
                        >
                          <span v-if="item.movimiento_subcuentas[1].monto > 0">
                            {{
                              saldoMovimiento(
                                item.movimiento_subcuentas[1].monto
                              )
                            }}</span
                          >
                          <span v-else>
                            -
                          </span>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </template>

                      <template
                        v-slot:[`item.movimiento_subcuentas[0].fecha_rendicion`]="{
                          item,
                        }"
                        ><div
                          v-if="
                            item.movimiento_subcuentas &&
                              item.movimiento_subcuentas.length > 1
                          "
                        >
                          <div
                            v-if="
                              item.movimiento_subcuentas[0].fecha_rendicion ==
                                ''
                            "
                          >
                            <span class="custom-table"> - </span>
                          </div>
                          <div v-else>
                            <span class="custom-table">{{
                              item.movimiento_subcuentas[0].fecha_rendicion
                            }}</span>
                          </div>
                        </div>
                      </template>

                      <!-- FECHA RENDICION -->
                      <template v-slot:[`item.fecha_rendicion`]="{ item }">
                        <div v-if="item.fecha_rendicion == ''">
                          <span class="custom-table">-</span>
                        </div>
                        <div v-else>
                          <span class="custom-table">{{
                            item.fecha_rendicion
                          }}</span>
                        </div>
                      </template>

                      <!-- REVERSIÓN -->
                      <template v-slot:[`item.revertido`]="{ item }">
                        <div v-if="item.revertido">
                          <span class="custom-table">Revertido</span>
                        </div>
                        <div v-else>
                          <span> - </span>
                        </div>
                      </template>

                      <!-- OBSERVACIÓN -->
                      <template v-slot:[`item.enobservacion`]="{ item }">
                        {{ item.enobservacion ? "Sí" : "-" }}
                      </template>

                      <!-- cantidad de registros por pagina -> actualizar numero de registros por pagina -->
                      <template v-slot:footer>
                        <v-row class="no-gutters mt-2 mx-2">
                          <v-flex xs12>
                            <span>Mostrar</span>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  dark
                                  text
                                  color="primary"
                                  class="ml-2"
                                  v-on="on"
                                >
                                  {{ rowsPerPage }}
                                  <v-icon>arrow_drop_down</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(number, index) in rowsPerPageArray"
                                  :key="index"
                                  @click="updateRowsPerPage(number)"
                                >
                                  <v-list-item-title>{{
                                    number
                                  }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-flex>
                        </v-row>
                      </template>
                    </v-data-table>
                  </div>
                </div>

                <div class="text-center pt-2">
                  <v-pagination
                    :value="paginaActual"
                    :length="total"
                    :total-visible="visible"
                    @input="onPageChange($event)"
                  ></v-pagination>
                </div>
              </v-card>
            </section>
          </div>
        </v-container>
      </v-main>
      <!-- <v-btn
        class="md-5 mr-3 elevation-21"
        dark
        fab
        button
        right
        color="green darken-3"
        fixed
        @click="top"
      ></v-btn> -->
    </v-app>
  </div>
</template>

<script>
// importar componente saldo
import Saldo from "@/components/SaldoCuenta/Saldo.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Decimal from "decimal.js";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { Movimientos as movService } from "../../services/movimientos";
import formatImport, { formatImportFloat } from "../../utils/formatImport";
import formatPorcentaje from "../../utils/formatPorcentaje";

import formatNumReporte from "../../utils/formatNumReporte";

import pdfMake from "pdfmake/build/pdfmake";

// import PDFObject from "pdfobject";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "Movimientos",
  components: {
    Saldo,
  },
  data() {
    return {
      itemDetallesMov: [{ pago_id: "", pagointentos_id: "" }],
      dialog: false,
      // panel de filtros
      panel: 0,
      readonly: false,

      // datapicker filtro fechas
      menu1: false, // atributo que permite desplegar el date picker fecha desde
      menu2: false, // atributo que permite desplegar el date picker fecha hasta
      // campos para hacer referencia a valor de datapicker
      date1: "",
      date2: "",

      // Filter models.
      searchByConcepto: "",
      searchByReferencia: null,
      medios_pago: 0,
      isEditingCanal: true,

      movimientoSaldo: [],

      //  numero de registros por pagina
      rowsPerPageArray: [50, 100, 200],

      loadingExcel: false,
      loadingPDF: false,
      base64String: "",

      // datos que se exportan en excel
      export_data_movimientos: [],
      headersDetalleMov: [
        {
          text: "NOMBRE",
          align: "center",
          sorteble: false,
          value: "nombre",
        },
        {
          text: "CUIL/DNI",
          align: "center",
          sorteble: false,
          value: "dni",
        },
        {
          text: "CORREO",
          align: "center",
          sorteble: false,
          value: "correo",
        },
      ],
      //  cabecera del datatable
    };
  },

  beforeRouteLeave(to, from, next) {
    // Limpia el filtro cuando cambio de ruta
    // Establece el filtro en vacío
    this.$store.commit("movimientos/setConcepto", "");
    this.$store.commit("movimientos/setReferencia", "");
    next();
  },

  watch: {
    // en los cambios de estados de una variable se realiza peticion a api
    searchByConcepto(newValue) {
      const query = newValue;

      if (query.length > 0) {
        this.$store.commit("movimientos/setConcepto", query);
        this.$store.commit("movimientos/setPaginate", 1);
        this.getMovimientos();
      } else if (query.length == 0) {
        this.$store.commit("movimientos/setConcepto", query);
        this.$store.commit("movimientos/setPaginate", 1);
        this.getMovimientos();
      }
    },

    searchByReferencia(newValue) {
      const query = newValue;
      if (query.length > 0) {
        this.$store.commit("movimientos/setReferencia", query);
        this.$store.commit("movimientos/setPaginate", 1);
        this.getMovimientos();
      } else if (query.length == 0) {
        this.$store.commit("movimientos/setReferencia", query);
        this.$store.commit("movimientos/setPaginate", 1);
        this.getMovimientos();
      }
    },

    medios_pago(newValue) {
      this.$store.commit("movimientos/setMedioPago", newValue);
      this.$store.commit("movimientos/setPaginate", 1);
      this.isEditingCanal = false;
      this.getMovimientos();
    },

    getCurrentAccountId() {
      this.getMovimientos();
    },
  },

  methods: {
    ...mapActions("movimientos", ["getMovimientos", "getChannels"]),

    esSuperAdmin() {
      return this.roles.includes("psp.config.clientes.wee");
    },
    obtenerImporteNeto(item) {
      // Si `movimiento_subcuentas` es `null`, mostrar `monto`
      if (!item.movimiento_subcuentas) {
        return item.monto;
      }
      // Si `movimiento_subcuentas` tiene datos, mostrar el `monto` del primer subcuenta
      return item.movimiento_subcuentas[0]?.monto || item.monto;
    },

    csvExport() {
      this.getMovimientos(true).then(() => {
        let csvContent = "";
        this.format_export(this.movimientosCsv);
        csvContent += [
          Object.keys(this.export_data_movimientos[0]).join(";"),
          ...this.export_data_movimientos.map((item) =>
            Object.values(item).join(";")
          ),
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

        const data = encodeURIComponent(csvContent);

        //const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + data
        );
        link.setAttribute("download", "movimientos.csv");
        link.click();
      });
    },

    convertImageToBase64() {
      const imagePath = "/assets/img/wee_logo.png"; // Ajusta el path a tu imagen
      fetch(imagePath)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64String = reader.result;
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          // console.error("Error fetching image:", error)
        });
    },

    async generateExcel() {
      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet("Sheet1");

      // Add the image to the workbook
      const imageId = workbook.addImage({
        base64: this.base64String,
        extension: "png",
      });

      sheet.addImage(imageId, "A1:A4");

      // Verificar si se deben agregar las columnas de subcuentas
      const hasSubcuentaData = this.movimientoSaldo.some(
        (mov) => mov.importe_split || mov.porcentaje_split || mov.costo_servicio
      );

      // const hasCostoServicio = this.mostrarCostoServicio;
      const hasCostoServicio = false;

      // Data for the table
      const tableData = [
        ["", "", "", ""], // salto de línea
        ["Cliente:", this.CLIENTE_CUENTA_DATA.cliente],
        [
          "Cuenta:",
          this.CLIENTE_CUENTA_DATA.cuenta.find(
            (c) => c.id == this.getCurrentAccountId
          ).cuenta,
        ],
        ["Nro de cuenta:", formatNumReporte(this.getCurrentAccountId), "", ""],
        [
          "Período:",
          `${this.FormatDateDesde} al ${this.FormatDateHasta}`,
          "",
          "",
          "",
        ],
        this.roles.includes("psp.config.clientes.wee")
          ? ["Saldo disponible:", formatImportFloat(this.saldo), "", ""]
          : null,

        ["", "", "", ""], // salto de línea
        hasSubcuentaData
          ? hasCostoServicio
            ? [
                "Fecha",
                "Débito/Crédito",
                "Monto",
                "Importe Rendido Split",
                "Porcentaje Split",
                "Cargo por servicio",
              ]
            : [
                "Fecha",
                "Débito/Crédito",
                "Monto",
                "Importe Rendido Split",
                "Porcentaje Split",
              ]
          : hasCostoServicio
          ? //Costo Servicio secundaria sin datos de Mov_subuenta
            [
              "Fecha",
              "Débito/Crédito",
              "Monto",
              "Importe Rendido Split",
              "Porcentaje Split",
            ]
          : this.roles.includes("psp.config.clientes.wee")
          ? ["Fecha", "Débito/Crédito", "Monto", "Saldo"]
          : ["Fecha", "Débito/Crédito", "Monto"],
        ...this.movimientoSaldo.map((mov) =>
          hasSubcuentaData
            ? hasCostoServicio
              ? [
                  mov.fecha,
                  mov.tipo,
                  formatImport(mov.monto),
                  mov.importe_split
                    ? formatImport(mov.importe_split)
                    : "$ 0,00",
                  mov.porcentaje_split ? `${mov.porcentaje_split}%` : "0%",
                  mov.costo_servicio
                    ? formatImport(mov.costo_servicio)
                    : "$ 0,00",
                ]
              : [
                  mov.fecha,
                  mov.tipo,
                  formatImport(mov.monto),
                  mov.importe_split
                    ? formatImport(mov.importe_split)
                    : "$ 0,00",
                  mov.porcentaje_split ? `${mov.porcentaje_split}%` : "0%",
                ]
            : hasCostoServicio
            ? //Cuenta Secundaria de Costo de Servicio
              [
                mov.fecha,
                mov.tipo,
                formatImport(mov.monto),
                mov.importe_split ? formatImport(mov.importe_split) : "$ 0,00",
                mov.porcentaje_split ? `${mov.porcentaje_split}%` : "0%",
                mov.costo_servicio
                  ? formatImport(mov.costo_servicio)
                  : "$ 0,00",
              ]
            : this.roles.includes("psp.config.clientes.wee")
            ? [
                mov.fecha,
                mov.tipo,
                formatImport(mov.monto),
                mov.saldo ? formatImport(mov.saldo) : "$ 0,00",
              ]
            : [mov.fecha, mov.tipo, formatImport(mov.monto)]
        ),
      ];

      // Add the table data to the sheet
      sheet.addRows(tableData);

      // Estilo de la fila 6
      const sixRow = sheet.getRow(6);
      sixRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "CCCCCC" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      // Estilo de los encabezados
      const headerRow = sheet.getRow(13);
      headerRow.font = { bold: true };
      headerRow.alignment = { horizontal: "center" };
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "CCCCCC" },
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      // Ajustar el ancho de las celdas según su contenido
      sheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 10;
          if (length > maxLength) {
            maxLength = length;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength;
      });

      // Generate the Excel file and download it
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer]),
        `movimientos_${this.CLIENTE_CUENTA_DATA.cliente
          .toLowerCase()
          .replaceAll(" ", "_")}.xlsx`
      );
    },

    async getMovimientoSaldo(pdf = false) {
      try {
        const params = {
          // CuentaId: this.getCurrentAccountId,
          FechaInicio: `${this.fechaDesde}T00:00:00-03:00`,
          FechaFin: `${this.fechaHasta}T00:00:00-03:00`,
        };

        pdf ? (this.loadingPDF = true) : (this.loadingExcel = true);

        let data; // Declarar la variable `data` fuera del bloque condicional

        // if (
        //   this.subcuentas &&
        //   (this.mostrarCostoServicio || this.mostrarPorcentaje)
        // ) {
        //   params.SubcuentasIds = this.subcuentas;
        //   if (this.mostrarCostoServicio) {
        //     params.AplicaCostoServicio = this.mostrarCostoServicio;
        //   }
        //   if (this.mostrarPorcentaje) {
        //     params.AplicaPorcentaje = this.mostrarPorcentaje;
        //   }
        //   params.CuentaId = this.getCurrentAccountId;
        //   ({ data } = await movService.getMovimientoSaldoSubcuentas(params));
        // } else {
        params.CuentaId = this.getCurrentAccountId;
        ({ data } = await movService.getMovimientoSaldo(params));
        // }

        // const { data } = await movService.getMovimientoSaldo(params);

        // ocultar los movimientos de fecha actual
        this.movimientoSaldo = data.data;

        pdf ? this.generatePDF() : this.generateExcel();

        this.loadingExcel = false;
        this.loadingPDF = false;
      } catch (error) {
        // console.error(error);
        this.loadingExcel = false;
        this.loadingPDF = false;
      }
    },

    format_export(arrData) {
      this.export_data_movimientos = arrData.map((item) => ({
        Fecha_movimiento: moment(item.movimiento_created_at).format(
          "DD/MM/YYYY"
        ),
        Concepto: item.pago_tipo,
        Referencia: item.external_reference,
        "Tipo de pago": item.channels,
        "Importe SP": this.saldoMovimiento(item.montosp, true),
        "Importe pagado": this.saldoMovimiento(item.montopagado, true),
        Comision: item.comision?.total
          ? this.saldoMovimiento(item.comision?.total, true)
          : "-",
        Impuesto: item.impuestos.total
          ? this.saldoMovimiento(item.impuestos.total, true)
          : "-",
        "Importe Neto": this.saldoMovimiento(item.monto, true),
        "Fecha Rendición": item.fecha_rendicion || "-",
      }));
    },

    limpiar_filtro_canales() {
      this.$store.commit("movimientos/setMedioPago", 0);
      this.$store.commit("movimientos/setPaginate", 1);
      this.getMovimientos();
    },

    // paginar datatable
    onPageChange(event) {
      this.$store.commit("movimientos/setPaginate", event);
      this.getMovimientos();
    },

    // registros por paginas (actualizar numero de registros por pagina) (listo)
    updateRowsPerPage(number) {
      this.$store.commit("movimientos/setrowsPerPage", number);
      this.$store.commit("movimientos/setPaginate", 1);
      this.getMovimientos();
    },

    saldoMovimiento(monto, sinSigno = false) {
      var str = monto / 100;
      if (!sinSigno) {
        return str.toLocaleString("es-ar", {
          style: "currency",
          currency: "ARS",
          minimumFractionDigits: 2,
        });
      } else {
        return str
          .toLocaleString("es-ar", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2,
          })
          .replace(/[$ ]/g, "")
          .replace(/\s+/g, "");
      }
    },

    async generatePDF() {
      // Verificar si se deben agregar las columnas de subcuentas
      const hasSubcuentaData = this.movimientoSaldo.some(
        (mov) => mov.importe_split || mov.porcentaje_split
      );

      // const hasCostoServicio = this.mostrarCostoServicio;
      const hasCostoServicio = false;

      const contenido = [
        {
          alignment: "justify",
          margin: [0, 20, 0, 0],
          columns: [
            {
              stack: [
                {
                  image: this.base64String,
                  width: 60,
                  absolutePosition: { x: 30, y: 20 },
                },
              ],
            },
          ],
        },
        {
          text: `Cliente: ${this.CLIENTE_CUENTA_DATA.cliente}`,
          style: "table",
          margin: [0, 10, 0, 4],
        },
        {
          text: `Cuenta: ${
            this.CLIENTE_CUENTA_DATA.cuenta.find(
              (c) => c.id == this.getCurrentAccountId
            ).cuenta
          }`,
          style: "table",
          margin: [0, 0, 0, 4],
        },
        {
          text: `Nro de cuenta: ${formatNumReporte(this.getCurrentAccountId)}`,
          style: "table",
          margin: [0, 0, 0, 4],
        },
        this.roles.includes("psp.config.clientes.wee")
          ? {
              text: `Saldo disponible: ${formatImportFloat(this.saldo)}`,
              style: "table",
              margin: [0, 0, 0, 4],
            }
          : null,
        {
          text: `Período: ${this.FormatDateDesde} al ${this.FormatDateHasta}`,
          style: "table",
          margin: [0, 0, 0, 15],
        },
        {
          table: {
            widths: hasSubcuentaData
              ? hasCostoServicio
                ? //Aplica Costo Servicio y tiene SubcuentaData (Principal)
                  ["auto", "auto", "auto", "auto", "auto"]
                : //Aplica Porcentaje Principal y Secundaria
                  ["auto", "auto", "auto", "auto", "auto"]
              : // Es Costo Servicio(Secundaria) pero sin subcuentaData
              hasCostoServicio
              ? ["auto", "auto", "auto", "auto"]
              : //No es Costo servicio ni tiene subcuenta data
              this.roles.includes("psp.config.clientes.wee")
              ? ["auto", "auto", "auto", "auto"]
              : ["auto", "auto", "auto"],
            headerRows: 1,
            body: [
              hasSubcuentaData
                ? hasCostoServicio
                  ? //Es costo Servicio y tiene datos sub
                    [
                      "Fecha",
                      "Débito/Crédito",
                      "Monto",
                      "Importe Neto",
                      "Cargo por servicio",
                    ]
                  : // Es Porcentaje y tiene datos sub
                    [
                      "Fecha",
                      "Débito/Crédito",
                      "Monto",
                      "Importe Split",
                      "Porcentaje Split",
                    ]
                : //es Costo Servicio pero sin subcuentaData osea Secundaria
                hasCostoServicio
                ? ["Fecha", "Débito/Crédito", "Monto", "Importe Neto"]
                : //no es costo serivio secundaria ni porcentaje
                this.roles.includes("psp.config.clientes.wee")
                ? ["Fecha", "Débito/Crédito", "Monto", "Saldo"]
                : ["Fecha", "Débito/Crédito", "Monto"],

              ...this.movimientoSaldo.map((mov) =>
                hasSubcuentaData
                  ? hasCostoServicio
                    ? [
                        mov.fecha,
                        mov.tipo,
                        formatImport(mov.monto),
                        mov.importe_split
                          ? formatImport(mov.importe_split)
                          : "$ 0,00",
                        mov.costo_servicio
                          ? formatImport(mov.costo_servicio)
                          : "$ 0,00",
                      ]
                    : //Es porcentaje
                      [
                        mov.fecha,
                        mov.tipo,
                        formatImport(mov.monto),
                        mov.importe_split
                          ? formatImport(mov.importe_split)
                          : "$ 0,00",
                        mov.porcentaje_split
                          ? `${mov.porcentaje_split}%`
                          : "0%",
                      ]
                  : //Costo Servicio Secundaria sin Data de Subcuenta
                  hasCostoServicio
                  ? [
                      mov.fecha,
                      mov.tipo,
                      formatImport(mov.monto),
                      mov.importe_split
                        ? formatImport(mov.importe_split)
                        : "$ 0,00",
                      mov.costo_servicio
                        ? formatImport(mov.costo_servicio)
                        : "$ 0,00",
                    ]
                  : //No es Costo Servicio Secundaria ni Porcentaje
                  this.roles.includes("psp.config.clientes.wee")
                  ? [
                      mov.fecha,
                      mov.tipo,
                      formatImport(mov.monto),
                      mov.saldo ? formatImport(mov.saldo) : "$ 0,00",
                    ]
                  : [mov.fecha, mov.tipo, formatImport(mov.monto)]
              ),
            ],
          },
          layout: "lightHorizontalLines",
          style: "table",
        },
      ];

      const styles = {
        header: {
          fontSize: 10,
          alignment: "left",
          margin: [0, 5, 0, 0],
        },
        subheader: {
          fontSize: 11,
          bold: true,
          alignment: "left",
          margin: [0, 5, 0, 10],
        },
        table: {
          fontSize: 9,
          bold: false,
        },
      };

      const docDefinition = {
        content: contenido,
        styles: styles,
      };

      // DESCARGAR
      pdfMake
        .createPdf(docDefinition)
        .download(
          `movimientos_${this.CLIENTE_CUENTA_DATA.cliente
            .toLowerCase()
            .replaceAll(" ", "_")}.pdf`
        );
    },

    saldoImpuestosComision(monto) {
      var str = monto / 100;
      return str.toLocaleString("es-ar", { minimumFractionDigits: 2 });
    },

    montoTotal(monto, comision, impuesto) {
      let a = new Decimal(monto);
      let b = new Decimal(comision);
      let c = new Decimal(impuesto);
      // let agregado = b.plus(c).toNumber();
      return a
        .plus(b)
        .plus(c)
        .toNumber();
    },

    emptyValueFilter(value) {
      if (!value) {
        return " ... ";
      }
    },
    formatFecha(value) {
      var date = new Date(value);
      let fecha = date.toISOString().substring(0, 10);
      var datePart = fecha.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1],
        day = datePart[2];

      return day + "/" + month + "/" + year;
    },
    // Este metodo devuelve un string, que corresponde a una clase css, dependiendo del valor del movimiento
    // Permite asignar de forma dinamica una clase css en relacion a un valor
    getColor(value) {
      if (value >= 0) return "success--text-custom";
      else return "red--text";
    },
    getColorChip(value) {
      return value < 0 ? "red lighten-1" : "green lighten-1";
    },
    // handleRowClick(value) {
    //   @click:row="handleRowClick"
    //   this.$router.push("Pagos");
    // },

    inicio(value) {
      this.$store.commit("movimientos/setFechaInicio", value);
      this.menu1 = false;
      this.getMovimientos();
    },
    fin(value) {
      this.$store.commit("movimientos/setFechaFin", value);
      this.menu2 = false;
      this.getMovimientos();
    },
    tipoMovimiento(value) {
      return value == "C" ? "Ingreso" : "Egreso";
    },

    async showDetails(item) {
      this.dialog = true;

      const data = {
        ...item.pagador,
        pago_id: item.pago_id,
        pagointentos_id: item.pagointentos_id,
      };

      this.itemDetallesMov = [data];
    },

    top() {
      window.scrollTo(0, 0);
    },
  },

  mounted() {
    const _scrollableContainer = document.getElementById(
      "_scrollableContainer"
    );
    const _contentContainer = document.getElementById("_contentContainer");
    const dummy = document.getElementById("dummy");

    const updateScrollers = () => {
      dummy.style.width = _contentContainer.scrollWidth + "px";
    };
    updateScrollers();
    window.addEventListener("resize", updateScrollers);

    const linkScroller = (a, b) => {
      a.addEventListener("scroll", (e) => {
        b.scrollLeft = e.target.scrollLeft;
      });
    };
    [
      [_scrollableContainer, _contentContainer],
      [_contentContainer, _scrollableContainer],
    ].forEach((args) => linkScroller(...args));

    // // En teoria, esto evita recargar los datos cada vez que se cambia de una vista de componentes
    // if (this.movimientos.length == 0) {
    //   this.getMovimientos();
    // }
    // if (this.medios_pagos.length == 0) {
    //   this.getChannels();
    // }
    this.getMovimientos();

    this.getChannels();

    if (this.isSuperAdmin) {
      this.headersGeneral.unshift({
        text: "ID Pago",
        align: "start",
        value: "pago_id",
      });

      this.headersGeneral.unshift({
        text: "Fecha Movimiento",
        align: "center",
        value: "movimiento_created_at",
      });

      this.headersGeneral.push({
        text: "Observación",
        align: "center",
        sortable: false,
        value: "enobservacion",
      });
    } else {
      this.headersGeneral.unshift({
        text: "Fecha Movimiento",
        align: "center",
        value: "movimiento_created_at",
      });
    }

    // ocultando columna cuando el cliente no es sujeto retencion
    if (!this.CLIENTE_CUENTA_DATA.sujeto_retencion) {
      this.headersGeneral = this.headersGeneral.filter(
        (h) => h.value !== "retenciones.MontoTotal"
      );
    }

    this.convertImageToBase64();
  },

  computed: {
    ...mapState("movimientos", [
      "movimientos",
      "movimientosCsv",
      "page",
      "total",
      "visible",
      "rowsPerPage",
      "cargando",
      "errored",
      "medios_pagos",
      "medio_pago",
    ]),

    ...mapState("saldo", ["saldo", "subcuentas"]),

    ...mapGetters("movimientos", ["fechaDesde", "fechaHasta", "canalespago"]),

    ...mapGetters([
      "isSuperAdmin",
      "CLIENTE_CUENTA_DATA",
      "getCurrentAccountId",
      "roles",
    ]),

    /*  para mostrar la fecha del saldo actual */
    fechaSaldo() {
      return moment().format("DD/MM/YYYY");
    },

    paginaActual: {
      get() {
        return this.$store.getters["movimientos/pages"];
      },
    },

    mostrarCostoServicio() {
      const currentAccount = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id == this.getCurrentAccountId
      );

      return currentAccount.costo_servicio;
    },

    subcuentaPrincipal() {
      const currentAccount = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id == this.getCurrentAccountId
      );
      return currentAccount.tipo_subcuenta;
    },

    hasMovimientoSubcuentas() {
      return this.movimientos.some(
        (movimiento) =>
          movimiento.movimiento_subcuentas &&
          movimiento.movimiento_subcuentas.length > 0
      );
    },

    mostrarPorcentaje() {
      const currentAccount = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id == this.getCurrentAccountId
      );

      return currentAccount.aplica_porcentaje;
    },

    headersGeneral() {
      const headers = [
        // Concepto
        {
          text: "Concepto",
          align: "center",
          sortable: false,
          value: "pago_tipo",
        },
        // Referencia de pago
        {
          text: "Referencia de Pago",
          align: "center",
          sortable: false,
          value: "external_reference",
        },
        // Tipo de Pago
        {
          text: "Tipo de pago",
          align: "center",
          sortable: false,
          value: "channels",
        },
        // Importe SP
        {
          text: "Importe SP",
          align: "start",
          sortable: false,
          value: "montosp",
        },
        // Importe Pagado
        {
          text: "Importe Pagado",
          align: "start",
          sortable: false,
          value: "montopagado",
        },

        // Comision
        {
          text: "Comisión",
          align: "center",
          sortable: false,
          value: "comision",
        },
        //Impuesto
        {
          text: "Impuesto",
          align: "center",
          sortable: false,
          value: "impuestos",
        },

        this.mostrarCostoServicio
          ? {
              text: "Cargo por servicio",
              align: "center",
              sortable: false,
              value: "costo_servicio",
            }
          : null,
        // {
        //   text: "Importe rendido Split",
        //   align: "center",
        //   sortable: false,
        //   value: "movimiento_subcuentas[0].monto",
        // }, // Importe Neto
        //Porcentaje Split
        this.mostrarPorcentaje
          ? {
              text: "Porcentaje Split",
              align: "center",
              sortable: false,
              value: "movimiento_subcuentas[0].porcentaje_aplicado",
            }
          : null,
        {
          text: "Importe Neto",
          align: "center",
          sortable: false,
          value: "monto",
        },

        {
          text: "Retención",
          align: "center",
          sortable: false,
          value: "retenciones.MontoTotal",
        },

        // Fecha Rendicion
        {
          text: "Fecha Rendición",
          align: "center",
          sortable: false,
          value: this.hasMovimientoSubcuentas
            ? "movimiento_subcuentas[0].fecha_rendicion"
            : "fecha_rendicion",
        },
        // Reversion
        {
          text: "Reversión",
          align: "center",
          sortable: false,
          value: "revertido",
        },
        // El header de la columna observacion pone de manera dinamica en mounted dependiendo del rol del usuario
      ];
      return headers.filter((header) => header !== null);
    },

    Canales() {
      return this.canalespago;
    },

    FormatDateDesde() {
      return this.fechaDesde
        ? moment(this.fechaDesde).format("DD/MM/YYYY")
        : "";
    },
    FormatDateHasta() {
      return this.fechaHasta
        ? moment(this.fechaHasta).format("DD/MM/YYYY")
        : "";
    },

    FormatPorcentaje() {
      return formatPorcentaje;
    },

    existenMovimientos() {
      return this.movimientos.length > 0;
    },

    headers() {
      // Verifica si 'movimientos.movimiento_subcuentas' no está vacío
      const hasMovimientoSubcuentas = this.movimientos.some(
        (movimiento) =>
          movimiento.movimiento_subcuentas &&
          movimiento.movimiento_subcuentas.length > 0
      );

      // Filtra los headers según la condición
      return this.headersGeneral.filter((header) => {
        // Si el valor del header es uno de los que depende de 'movimiento_subcuentas', se filtra dependiendo de la condición
        if (
          header.value === "movimiento_subcuentas[0].monto" ||
          header.value === "movimiento_subcuentas[0].porcentaje_aplicado" ||
          header.value === "movimiento_subcuentas[1].monto"
        ) {
          return hasMovimientoSubcuentas;
        }
        // Otros headers se muestran siempre
        return true;
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 1640px) {
  .custom-table {
    font-size: 12px;
  }
}

#_scrollableContainer,
#_contentContainer {
  overflow-x: auto;
}

.custom-content {
  width: 80vw;
}

#_scrollableContainer > div {
  height: 1px;
}

/* .trow {
  transition: transform 0.3s;
}
.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */

.v-application .text-subtitle-2 {
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.1rem;
  letter-spacing: 0.0071428571em !important;
  font-family: "Roboto", sans-serif !important;
}
.outline_none {
  outline: none;
}

.success--text-custom {
  color: #228b22; /* Dark green color */
}
</style>
