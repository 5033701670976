const formatImport = (monto, sinSigno) => {
  const str = monto / 100;

  const format = str.toLocaleString("es-ar", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
  });

  if (sinSigno) return format.substring(2).replace(/\s/g, "-");

  return format;
};

export const formatImportFloat = (monto, sinSigno) => {
  if (!monto) return;
  const format = monto.toLocaleString("es-ar", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
  });

  if (sinSigno) return format.substring(2).replace(/\s/g, "-");

  return format;
};

export default formatImport;
