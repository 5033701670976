<template>
  <div>
    <h3 class="title">Solicitudes de Pagos</h3>
    <p>
      En esta sección se cubrirán aspectos técnicos y de flujo de información,
      para utilizar solicitudes de pago con la API de WEE!
    </p>

    <!-- subtitulo crear solicitud de pago -->
    <v-divider id="solicitud-pago"></v-divider>
    <h4 class="bold mb-2">Crear Solicitud de Pago</h4>
    <p>Crea una nueva solicitud de pago.</p>
    <h4 class="bold my-4">REQUEST</h4>

    <!-- -------------HEADERS--------------- -->
    <h4 class="my-4">Headers</h4>
    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">REQUERIDO</th>
            <th class="text-left">PATTERN</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in postHeaders" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>
              <code
                style="
                  background-color: #f5f5f5;
                  padding: 2px 4px;
                  border-radius: 4px;
                  color: #000;
                "
                >{{ item.pattern }}
              </code>
            </td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- -------------BODY--------------- -->
    <h4 class="my-4">Body</h4>

    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">REQUERIDO</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in body" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- -----Atributos del objeto items (REQUEST)-------- -->
    <p class="my-4">Atributos del objeto <span class="bold">items</span>.</p>

    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">REQUERIDO</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in itemsObjectRequest" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.requerido }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- ----------------RESPUESTA------------------ -->
    <h4 class="bold mt-4 mb-2">RESPONSE</h4>
    <p class="mb-4">Atributos del objeto de la respuesta.</p>
    <v-simple-table fixed-header height="190px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in responseFirstPart" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p class="my-4">
      Atributos del objeto de <span class="italic">data</span>.
    </p>
    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in response" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- --------Atributos del objeto items (RESPONSE) -->
    <p class="my-4">Atributos del objeto <span class="italic">items</span>.</p>
    <v-simple-table fixed-header height="200px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in itemsObjectResponse" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- POST Crear Solicitud de Pago -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <h4 class="bold mb-4">
      <span class="metodo-post">POST</span> Crear Solicitud de Pago
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p class="d-flex align-items-center justify-content-center mb-0">
        https://sb-wee.ar/v1/checkout
      </p>
    </div>
    <!-- Headers -->
    <h4 class="bold mt-4">HEADERS</h4>
    <v-divider></v-divider>
    <div>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Content-Type</p>
        </v-col>
        <v-col cols="6">
          <p>application/json</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <p class="bold ml-4">Authorization</p>
        </v-col>
        <v-col cols="6">
          <p>API Key</p>
        </v-col>
      </v-row>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: REQUEST --------------------- -->
    <div>
      <h6 class="bold mt-4">Example Request</h6>
      <div class="mt-2 mb-4">
        <pre style="padding-top: 0px">
         <!--  <div class="d-flex justify-content-end">
            <v-icon
            color="#fff"
             @click="copyTextNoInput()">
             {{ message ? "mdi-clipboard-check-outline" : "mdi-clipboard-outline" }}
            </v-icon>
          </div> -->
        <code 
        ref="message"
        class="language-json code-block">
    {
          "payer_name":"juan gomez",
          "payer_type": "DNI",
          "payer_number": "34543234",
          "payer_email": "juan.gomez@telco.com.ar",
          "description": "Pago impuestos - DGR nueva prue 30062022",
          "first_total": 1500,
          "first_due_date": "15-08-2022",
          "external_reference": "257370",
          "second_due_date": "30-08-2022",
          "second_total": 2000,
          "payment_type": "PAGO SERVICIO",
          "items": [
              { 
                "quantity": 1,
                "description":"Pago de impuesto al sello",
                "amount": 1000,
                "identifier": "11223344"
              },
              {
                "quantity": 1,
                "description":"Pago de impuesto al sello",
                "amount": 500,
                "identifier": "11223355"
              }
          ]
    }
    </code>
  </pre>
      </div>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: RESPONSE --------------------- -->
    <div>
      <h6 class="bold mt-4">Example Response</h6>
      <div class="mt-2 mb-4">
        <pre>  
        <code class="language-json code-block" >
    {
      "data": {
          "id": 578,
          "estado": "pending",
          "description": "Pago impuestos - DGR nueva prue 30062022",
          "first_due_date": "26-08-2022",
          "first_total": 15,
          "second_due_date": "30-08-2022",
          "second_total": 20,
          "payer_name": "juan gomez",
          "payer_email": "juan.gomez@telco.com.ar",
          "external_reference": "257370",
          "metadata": "",
          "uuid": "f930b9d5-2a54-4532-be33-1ec32b0b4afb",
          "checkout_url": "https://sb-wee.ar/v1/checkout/f930b9d5-2a54-4532-be33-1ec32b0b4afb",
          "pdf_url": "https://sb-wee.ar/v1/checkout/f930b9d5-2a54-4532-be33-1ec32b0b4afb",
          "created_at": "25-08-2022",
          "items": [
              {
              "quantity": 1,
              "description": "Pago de impuesto al sello",
              "amount": 10,
              "identifier": "11223344"
              },
              {
              "quantity": 1,
              "description": "Pago de impuesto al sello",
              "amount": 5,
              "identifier": "11223355"
              }
          ]
      },
      "message": "Solicitud de pago generada",
      "status": true
}
    </code>
  </pre>
      </div>
    </div>
    <p class="py-4">
      Cuando recibe la respuesta para ingresar al CheckOut debe copiar el valor
      del atributo "<span class="bold">checkout_url</span>" de la respuesta e
      ingresarla en el navegador.
    </p>
    <!-------------------------------------------------------------- REDIRECCIONES DE LA TRANSACCION -------------------------------------------------->
    <h4 id="redirecciones-transaccion" class="bold pt-4 mb-2">
      Redirecciones de la Transacción
    </h4>
    <div>
      <p class="pt-2 pb-4">
        Se configuran las URLs de las páginas de respuesta para cuando una
        transacción es aprobada o pendiente. Estos campos no son obligatorios, y
        en caso de no especificar una URL, se redireccionará a la página de
        respuesta por defecto de WEE!
      </p>
      <!-- ------------------COMPRA EXITOSA ---------------------- -->
      <v-row>
        <v-col>
          <p class="bold px-5 mx-5">
            Si la compra es exitosa, tus usuarios serán dirigidos a:
          </p>
        </v-col>
        <v-col class="px-5 mx-5">
          <!--URL de la solicitud-->
          <div class="url-container">
            <p class="d-flex align-items-center justify-content-center mb-0">
              Ejemplo:
              <span class="italic ml-2"> https://sb-wee.ar/v1/success</span>
            </p>
          </div>
          <p class="italic" style="font-size: 12px">
            Tus pagadores regresarán a esta página al finalizar el proceso del
            pago
          </p>
        </v-col>
      </v-row>
      <!-- ------------------COMPRA PENDIENTE ---------------------- -->
      <v-row>
        <v-col>
          <p class="bold px-5 mx-5">
            Si la compra queda pendiente, tus usuarios serán dirigidos a:
          </p>
        </v-col>
        <v-col class="px-5 mx-5">
          <!--URL de la solicitud-->
          <div class="url-container">
            <p class="d-flex align-items-center justify-content-center mb-0">
              Ejemplo:
              <span class="italic ml-2"> https://sb-wee.ar/v1/pending</span>
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- ------------------------------webhooks --------------------------------- -->
    <h4 id="webhooks" class="bold pt-4 mb-2 mt-5">Integración con webHooks</h4>
    <p class="pt-2 pb-4">
      Debe implementar un EndPoint método post que pueda recibir un JSON con la
      siguiente estructura:
    </p>
    <h4 class="bold my-4">REQUEST</h4>
    <h4 class="my-4">Body</h4>
    <v-simple-table fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">ATRIBUTO</th>
            <th class="text-left">TIPO</th>
            <th class="text-left">DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in bodyWebHooks" :key="item.name">
            <td>{{ item.atributo }}</td>
            <td>{{ item.tipo }}</td>
            <td>{{ item.descripcion }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- POST Integracion con WEBHOOKS -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <h4 class="bold mb-4">
      <span class="metodo-post">POST</span> Integración con webHooks
    </h4>
    <!--URL de la solicitud-->
    <div class="url-container">
      <p class="d-flex align-items-center justify-content-center mb-0">
        Ejemplo: <span class="italic ml-2"> https://sb-wee.ar/v1/webhooks</span>
      </p>
    </div>
    <!-- --------------------BLOQUE CODIGO EJEMPLO: INTEGRACION CON WEBHOOKS POST --------------------- -->
    <div class="pb-4">
      <h6 class="bold mt-4">Example Request</h6>
      <div class="mt-2 mb-4">
        <pre>
        <code class="language-json code-block">
      [
        {
            "id": 6,
            "estado_pago": "APROBADO",
            "exito": true,
            "channel": 2,
            "description": "Jueves 01/09/2022",
            "firstDueDate": "2022-08-12 00:00:00",
            "first_total": 1.6,
            "second_due_date": "2022-08-12 00:00:00",
            "second_total": 2.6,
            "payer_name": "Sebas Pago",
            "payer_email": "sebastian.escobar@telco.com.ar",
            "external_reference": 62,
            "metadata": null,
            "pdf_url": "https://sb-wee.ar/v1/checkout/bill/fe9a304a-7681-48a4-94e3-5b8afb0e5370",
            "created_at": "2022-08-02 15:49:17",
            "importe_pagado": 1.6
        }
      ]
    </code>
  </pre>
      </div>
    </div>
    <!-- DIAGRAMA ESTADOS solicitud de pagos -->
    <v-divider></v-divider>
    <h4 class="bold my-4">Estados de una Solicitud de Pago</h4>
    <p>
      Los siguientes diagramas muestran la transición de los diferentes estados:
    </p>
    <div class="d-flex flex-column justify-center">
      <!-- ESTADOS CREDITO Y DEBITO -->
      <v-row>
        <v-col cols="6">
          <h5 class="bold mt-4 align-self-center">CREDITO Y DEBITO:</h5>
          <v-img
            height="600"
            contain
            src="/assets/img/estado-credito-debito.png"
            style="margin: 0 0 80px 0"
          ></v-img>
        </v-col>
        <v-col cols="6">
          <!-- ESTADOS OFFLINE -->
          <h5 class="mt-4 align-self-center">
            <span class="bold">OFFLINE</span> (RAPIPAGO Y MULTIPAGO):
          </h5>
          <v-img
            height="623"
            contain
            src="/assets/img/estado-offline.png"
            style="margin: 0 0 80px 0"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <!-- ESTADOS DEBIN -->
        <v-col cols="6">
          <h5 class="bold mt-4 align-self-center">DEBIN:</h5>
          <v-img
            height="550"
            contain
            src="/assets/img/estado-debin.png"
            style="margin: 0 0 80px 0"
          ></v-img
        ></v-col>
        <!-- ESTADOS QR -->
        <v-col cols="6">
          <h5 class="bold mt-4 align-self-center">QR:</h5>
          <v-img
            height="550"
            contain
            src="/assets/img/estado-qr.png"
            style="margin: 0 0 80px 0"
          ></v-img
        ></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Prism from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";
export default {
  name: "NewAyudaPagos",
  data() {
    return {
      message: "",
      postHeaders: [
        {
          atributo: "apiKey",
          tipo: "String",
          requerido: "SI",
          pattern: "API Key",
          descripcion: "aa7943e1-a60c-99e7-8812-xxxxxxxxxxxx",
        },
        {
          atributo: "Content-Type",
          tipo: "String",
          requerido: "SI",
          pattern: "application/json",
          descripcion:
            "El tipo MIME del contenido del cuerpo enviado. Por defecto JSON es Unicode UTF-8. No se debe configurar otro charset.",
        },
        {
          atributo: "Cache-Control",
          tipo: "String",
          requerido: "SI",
          pattern: "no-cache",
          descripcion: "",
        },
      ],
      body: [
        {
          atributo: "payer_name",
          tipo: "String",
          requerido: "SI",
          descripcion: "Nombre del pagador.",
        },
        {
          atributo: "payer_type",
          tipo: "String",
          requerido: "NO",
          descripcion: "Tipo de identificación (DNI o CUIL) del pagador.",
        },
        {
          atributo: "payer_number",
          tipo: "String",
          requerido: "NO",
          descripcion: "Número de identificación del pagador.",
        },
        {
          atributo: "payer_email",
          tipo: "String",
          requerido: "SI",
          descripcion: "Correo electrónico del pagador.",
        },
        {
          atributo: "description",
          tipo: "String",
          requerido: "SI",
          descripcion: "Descripción de la solicitud de pago.",
        },
        {
          atributo: "first_total",
          tipo: "Integer",
          requerido: "SI",
          descripcion:
            "Monto total antes de vencimiento. Valor entero. Los dos últimos dígitos se consideran decimales.",
        },
        {
          atributo: "first_due_date",
          tipo: "String",
          requerido: "SI",
          descripcion: "Fecha de 1º Vencimiento. Formato dd/mm/aaaa.",
        },
        {
          atributo: "external_reference",
          tipo: "String o Integer",
          requerido: "SI",
          descripcion:
            "Este atributo se puede utilizar como referencia para identificar la solicitud de pago y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "second_due_date",
          tipo: "String ",
          requerido: "NO",
          descripcion: "Fecha de 2º Vencimiento. Formato dd/mm/aaaa.",
        },
        {
          atributo: "second_total",
          tipo: "Integer",
          requerido: "NO",
          descripcion:
            "Monto total, valor entero. Los dos últimos dígitos se consideran decimales. Este valor se incluye solamente si existe fecha de 2º vencimiento.",
        },

        {
          atributo: "payment_type",
          tipo: "String",
          requerido: "SI",
          descripcion: "Tipificación del cobro.",
        },
        {
          atributo: "items",
          tipo: "Array [Object]",
          requerido: "SI",
          descripcion: "Lista detallada de productos o servicios.",
        },
        {
          atributo: "notificacion_url",
          tipo: "String",
          requerido: "NO",
          descripcion:
            "Url donde se va a notificar el pago, en caso de que el cliente tenga activado webhook dinámico.",
        },
        {
          atributo: "redireccion_url_success",
          tipo: "String",
          requerido: "NO",
          descripcion:
            "Url de redirección dinámica cuando el pago finaliza de forma exitosa/aprobada.",
        },
        {
          atributo: "redireccion_url_pending",
          tipo: "String",
          requerido: "NO",
          descripcion:
            "Url de redirección dinámica cuando el pago finaliza de forma exitosa/pendiente.",
        },
      ],

      itemsObjectRequest: [
        {
          atributo: "quantity",
          tipo: "Integer",
          requerido: "SI",
          descripcion: "Cantidad del producto o servicio.",
        },
        {
          atributo: "description",
          tipo: "String",
          requerido: "SI",
          descripcion: "Descripción del producto o servicio.",
        },
        {
          atributo: "amount",
          tipo: "Integer",
          requerido: "SI",
          descripcion: "Importe del producto o servicio.",
        },
        {
          atributo: "identifier",
          tipo: "String",
          requerido: "NO",
          descripcion: "Identificador del producto o servicio.",
        },
      ],
      responseFirstPart: [
        {
          atributo: "data",
          tipo: "Object",
          descripcion: "Objeto con la información de la solicitud de pago.",
        },
        {
          atributo: "message",
          tipo: "String",
          descripcion: "Mensaje de respuesta.",
        },
        {
          atributo: "status",
          tipo: "Boolean",
          descripcion: "Estado de la respuesta.",
        },
      ],
      response: [
        {
          atributo: "id",
          tipo: "Integer",
          descripcion: "Identificador único de pago.",
        },
        {
          atributo: "estado",
          tipo: "String",
          descripcion: "Estado inicial del pago.",
        },
        {
          atributo: "description",
          tipo: "String",
          descripcion: "Descripción del cobro indicado.",
        },
        {
          atributo: "first_due_date",
          tipo: "String",
          descripcion: "Fecha de vencimiento.",
        },
        {
          atributo: "first_total",
          tipo: "Integer",
          descripcion: "Monto total antes de vencimiento.",
        },
        {
          atributo: "second_due_date",
          tipo: "String",
          descripcion: "Fecha límite de validez del cobro.",
        },
        {
          atributo: "second_total",
          tipo: "Integer",
          descripcion: "Monto total luego de vencimiento.",
        },
        {
          atributo: "payer_email",
          tipo: "String",
          descripcion: "Correo electrónico del pagador.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          descripcion:
            "Este atributo se puede utilizar como referencia para identificar la solicitud de pago y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "metadata",
          tipo: "String",
          descripcion: "Información agregada para uso externo.",
        },
        {
          atributo: "uuid",
          tipo: "String",
          descripcion: "Identificador único de checkout.",
        },
        {
          atributo: "checkout_url",
          tipo: "String",
          descripcion: "Dirección web de acceso al checkout.",
        },

        {
          atributo: "created_at",
          tipo: "String",
          descripcion: "Fecha de creacion de la solicitud de pago.",
        },
        {
          atributo: "items",
          tipo: "Array [Object]",
          descripcion:
            "Objeto JSON que se puede utilizar para listar todos los productos o servicios asociados a la solicitud de pago, para que sean incluidos como un detalle del comprobante de pago.",
        },
      ],
      itemsObjectResponse: [
        {
          atributo: "quantity",
          tipo: "Integer",
          descripcion: "Cantidad del producto o servicio.",
        },
        {
          atributo: "description",
          tipo: "String",
          descripcion: "Descripción del producto o servicio.",
        },
        {
          atributo: "amount",
          tipo: "Integer",
          descripcion: "Monto del producto o servicio.",
        },
        {
          atributo: "identifier",
          tipo: "String",
          descripcion: "Identificador del producto o servicio.",
        },
      ],
      bodyWebHooks: [
        {
          atributo: "id",
          tipo: "Integer",
          descripcion: "Identificador único de pago.",
        },
        {
          atributo: "estado_pago",
          tipo: "String",
          descripcion:
            "Estados posibles: PENDIENTE, PROCESANDO, RECHAZADO, APROBADO, REVERTIDO, CADUCADO, AUTORIZADO.",
        },
        {
          atributo: "exito",
          tipo: "Boolean",
          descripcion: "Indica si el pago fue exitoso o no.",
        },
        {
          atributo: "channel",
          tipo: "String",
          descripcion: "Canal de pago.",
        },
        {
          atributo: "description",
          tipo: "String",
          descripcion: "Descripcion del pago.",
        },
        {
          atributo: "firstDueDate",
          tipo: "String",
          descripcion: "Fecha de 1º Vencimiento. Formato dd/mm/aaaa.",
        },
        {
          atributo: "first_total",
          tipo: "Float",
          descripcion: "Monto total antes de vencimiento.",
        },
        {
          atributo: "second_due_date",
          tipo: "String",
          descripcion: "Fecha de 2º Vencimiento. Formato dd/mm/aaaa.",
        },
        {
          atributo: "second_total",
          tipo: "Float",
          descripcion: "Monto total pasado el 1º vencimiento.",
        },
        {
          atributo: "payer_name",
          tipo: "String",
          descripcion: "Nombre del pagador.",
        },
        {
          atributo: "payer_email",
          tipo: "String",
          descripcion: "Correo electrónico del pagador.",
        },
        {
          atributo: "external_reference",
          tipo: "String",
          descripcion:
            "Este atributo se puede utilizar como referencia para identificar la solicitud de pago y sincronizar con tus sistemas de backend el origen de la operación.",
        },
        {
          atributo: "metadata",
          tipo: "String",
          descripcion: "Mas información del pago.",
        },
        {
          atributo: "barcode",
          tipo: "String",
          descripcion: "Código de barras solo para pagos offline.",
        },
        {
          atributo: "barcode_url",
          tipo: "String",
          descripcion: "URL del código de barras solo para pagos offline.",
        },
        {
          atributo: "pdf_url",
          tipo: "String",
          descripcion: "Comprobante de pago.",
        },
        {
          atributo: "created_at",
          tipo: "String",
          descripcion: "Fecha de creacion de la solicitud de pago.",
        },
        {
          atributo: "importe_pagado",
          tipo: "Float64",
          descripcion: "Importe pagado.",
        },
      ],
    };
  },
  mounted() {
    window.Prism = window.Prism || {};
    window.Prism.manual = true;
    Prism.highlightAll(); // highlight your code on mount
    //console.log(this.$router.currentRoute);
    // const anchor = this.$router.currentRoute.hash;
    // this.$nextTick(() => {
    //   if (anchor && document.querySelector(anchor)) {
    //     location.href = anchor;
    //   }
    // });
  },
  methods: {
    copyTextNoInput() {
      const textArea = document.createElement("textarea");
      textArea.value = this.$refs.message.innerText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.message = textArea.value; // Assign the copied value to the message variable
    },
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.title {
  font-size: 25px;
  font-family: Montserrat;
}

.metodo-post {
  color: rgb(173, 122, 3);
  font-size: 20px;
  margin-right: 10px;
}

.url-container {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  height: 40px;
  display: flex;
  padding-left: 10px;
}
.code-block {
  font-size: 14px;
}
.italic {
  font-style: italic;
}
</style>
