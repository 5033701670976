<template>
  <div class="content">
    <div class="row align-items-center">
      <div class="col-md-2 ms-3" align="left">
        <v-btn fab dark x-small color="primary">
          <router-link :to="`/cliente/${cliente_id}/cuentas`">
            <v-icon dark> arrow_back</v-icon>
          </router-link>
        </v-btn>
      </div>
     
      <div class="col" align="right">
        <v-dialog v-model="showForm" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> {{'Nuevo'}}</span>
              <v-spacer></v-spacer>
              <v-btn
                @click="showForm = false"
                icon
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form_create" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="currentCosto.nombre"
                    :counter="100"
                    :rules="nameRules"
                    label="Nombre"
                    required
                  ></v-text-field>
                  <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class=""
                          label="Fecha vigencia"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                          :value="FormatDateDesde()"
                          :rules="fecha_alta"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="currentCosto.vigencia_desde"
                        locale="es-ar"
                        no-title
                        scrollable
                        @input="menu1 = false"
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-text-field
                      v-model="currentCosto.costo_servicio"
                      :counter="5"
                      :rules="costo"
                      label="Porcentaje"
                      required
                      type="string"
                      suffix="%"
                      @input="formatInputCosto"
                    ></v-text-field>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="showForm = false"
              >
                Cerrar
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!valid"
                :loading="cargandoModal"
                @click="handleStore()"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <div class="col-md-12" align="right">
          <v-btn color="primary" dark @click="showForm = true" >
            Nuevo
          </v-btn>
        </div>
      </div>
    </div>

    <LayoutPage :title="'Costo de servicio'">
      <v-app>
        <v-main>
          <section v-if="errored">
            <v-alert
              border="left"
              class="mt-4 mx-3 elevation-3"
              color="red lighten-2"
              dark
            >
              Lo sentimos, no es posible obtener la información en este momento,
              por favor intente nuevamente mas tarde.
            </v-alert>
          </section>
          <v-data-table
            :search="search"
            :headers="headers"
            :items="options"
            :loading="cargando"
            :items-per-page="itemsPerPage"
            hide-default-footer
            disable-sort
            loading-text="Cargando... Espere por favor"
            no-results-text="No se encontraron registros"
            no-data-text="No se encontraron registros"
            class="elevation-1 trow"
          >
            <template v-slot:no-results>
              <v-alert color="error" icon="warning">
                found no results.
              </v-alert>
            </template>
            <!-- ACCIONES BOTONES -->

            <template v-slot:[`item.actions`]="{ item }">
              <v-row class="ms-2">
                <!-- 1 - boton delete -->
                <v-tooltip top color="red" minWidth="120px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab                                    
                      x-small
                      color="red"
                      @click="deleteComision(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark style="color:white;"> delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Comisión</span>
                </v-tooltip>

                
              </v-row>
            </template>
            <template v-slot:[`item.vigencia_desde`]="{ item }">
              {{moment(item.vigencia_desde, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY")}}
            </template>
            <template v-slot:[`item.costo_servicio`]="{ item }">
              {{ `${formatCostoServicio(item.costo_servicio)}%` }}
            </template>
            
            <template v-slot:footer>
              <v-row class="no-gutters mt-2 mx-2">
                <v-flex xs12 class="align-items-center">
                  <span>Mostrar</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn dark text color="primary" class="ml-2" v-on="on">
                        {{ rowsPerPage }}
                        <v-icon>arrow_drop_down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(number, index) in rowsPerPageArray"
                        :key="index"
                        @click="updateRowsPerPage(number)"
                      >
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-flex>
              </v-row>
            </template>
          </v-data-table>  
          <div class="text-center pt-2">
            <v-pagination
              v-model="pagination.page"
              :length="pagination.total"
              :total-visible="pagination.visible"
              @input="onPageChange"
            ></v-pagination>
          </div>
        </v-main>
      </v-app>
    </LayoutPage>
  </div>
</template>

<script>
import LayoutPage from "../../../LayoutPage.vue";
import costoServicio from "../../../../services/administrar/costoServicio.js";
import moment from "moment";
import formatImport from "../../../../utils/formatImport.js";

export default {
name: "CostoServicio",
components: { 
  LayoutPage 
},
data() {
  return {
    cliente_id: this.$route.params.cliente_id,
    cuentas_id: this.$route.params.cuenta_id,
    errored: false,
    showForm: false,
    //Datos Tabla
    options: [],
    search: "",
    headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Fecha vigencia", value: "vigencia_desde" },
        { text: "Costo servicio", value: "costo_servicio" },      
        { text: "Acciones", value: "actions", sortable: false },
      ],
    itemsPerPage: 10,
    pageCount: 0,
    // paginacion api
    rowsPerPageArray: [2,10, 20, 30],
    rowsPerPage: 10,
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    cargando: false,
    cargandoModal: false,
    valid: false,

    //Modal
    currentCosto:{
      nombre: "",
      vigencia_desde: "",      
      costo_servicio: "",
    },
    date1: '',
    menu1: false,

    //Reglas
    nameRules: [
        (v) => !!v || "Nombre es requerido",
        (v) =>
          (v && v.length <= 100) || "Nombre no debe superar los 100 caracteres",
        (v) =>
          /^[A-Za-z0-9.-\s]+$/.test(v) ||
          "Solo numero y letras, sin ñ o acentos.",
      ],
    fecha_alta: [(v) => !!v || "La fecha es requerida"],
    costo: [
      (v) => !!v || "El costo de servicio es requerido",
      (v) => (v && v.length < 6) || "Debe reducir el porcentaje",  
    ],
  };
},

created() {
  this.getDatos();
},
methods: {
  onPageChange() {
    this.getDatos();
  },
  updateRowsPerPage(number) {
    this.rowsPerPage = number;
    this.getDatos();
  },
  async deleteComision(item){      
    const { isConfirmed } = await this.$swal.fire({
      title: "¿Esta seguro?",
      text: "Esta acción eliminará el costo de servicio",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Si, continuar",
      denyButtonText: "Cancelar",
    });

    if (isConfirmed) this.delete(item.id, item.cuentas_id);
    
  },
  FormatDateDesde() {
    if (this.currentCosto && this.currentCosto.vigencia_desde) {
      return moment(this.currentCosto.vigencia_desde).format("DD/MM/YYYY");
    }
    return "";
  },  
  formatImport(value, decimals = false) {
    return formatImport(value, decimals);
  },
  handleStore() {
    if (this.$refs.form_create.validate()) {
      this.store();
    } else {
      this.$toastr.w("Verifique los datos"); 
    }
  },
  cerrar_create() {
    this.showForm = false;
    this.valid = false;
    this.resetCurrentItem();
    this.$refs.form_create.resetValidation();
  },
  resetCurrentItem(){
    this.currentCosto = {
      nombre: "",
      vigencia_desde: "",
      costo_servicio: "",
    }
  },
  formatValue(value) {
    if (!value) return "";
    return new Intl.NumberFormat("es-AR", {
      currency: "ARS",
      minimumFractionDigits: 2,
    }).format(value / 100);
  },
  formatInputCosto(value) {
    // Remove non-numeric characters
    let rawValue = value.replace(/[^\d]/g, "");
    if (rawValue) {
      this.currentCosto.costo_servicio = this.formatValue(rawValue);
    } else {
      this.currentCosto.costo_servicio = ""; 
    }
  },
  formatCostoServicio(coeficiente) {    
    return Number(((coeficiente - 1) * 100).toFixed(2));
  },
  getDatos: function() {
      this.errored = false;
      this.cargando = true;
      costoServicio.getCuentasComision({cuentasId: this.cuentas_id, size: this.rowsPerPage, number: this.pagination.page})
        .then((response) => {
          if (!response?.data.response_cuenta_costo_servicio) {
            this.options = [];
            this.pagination.page = 1;
            this.pagination.total = 1;
          } else {                      
            this.options = response.data.response_cuenta_costo_servicio;
            this.pagination.page = response.data?.meta?.page?.currentPage;
            this.pagination.total = response.data?.meta?.page?.lastPage;
          }
        })
        .catch((error) => {
          this.$toastr.e("Ocurrio un error al cargar las comisiones");
          this.errored = true;
        })        
        .finally(() => {
          this.cargando = false;
        });
  },
  store: function() {    
    let costo = this.currentCosto.costo_servicio.replace(/[$,]/g, "");
    let formattedCosto = costo.length <= 3 ? `1.0${costo}` : `1.${costo}`;
    formattedCosto = parseFloat(`${formattedCosto}`);

    let payload = {
      cuentas_id: this.cuentas_id,
      nombre: this.currentCosto.nombre,
      vigencia_desde: moment(this.currentCosto.vigencia_desde).format('YYYY-MM-DD') + 'T00:00:00Z',
      costo_servicio: formattedCosto,
    }
    this.cargandoModal = true;
    costoServicio.postCuentasComision(payload)
      .then((response) => {
        this.$toastr.s("Costo de servicio creado exitosamente");
        this.getDatos();
        this.cerrar_create();
      })
      .catch((error) => {
        if(error?.response?.data?.statusMessage) {
          this.$toastr.w(error?.response?.data?.statusMessage);          
        }else{
          this.$toastr.e("Ocurrio un error al crear el costo de servicio");
        }
      })
      .finally(() => {
        this.cargandoModal = false;
      });
  },
  delete: function(id, cuentas_id){
    let params = {
      Id: id,
      CuentasId: cuentas_id
    }
    this.cargando = true;
    costoServicio.deleteCuentasComision(params)
      .then((response) => {        
        this.$toastr.s("Costo de servicio eliminado exitosamente");
        this.getDatos();
      })
      .catch((error) => {
        this.$toastr.e("Ocurrio un error al eliminar el costo de servicio");
      })
      .finally(() => {
        this.getDatos();
        this.cargando = false;
      });
  }

}
}
</script>
<style scoped>
a[data-v-1b84c964] {
  color: #e6f1e3;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}
</style>
