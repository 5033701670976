import { Movimientos } from "../../services/movimientos.js";
import { Channels } from "../../services/channels.js";
import { Refresh } from "../../services/refreshToken";
import moment from "moment";
export default {
  namespaced: true,
  state: {
    // state de movimientos
    movimientos: [],

    //movimientos que se van a exportar csv
    movimientosCsv: [],

    //  filter fechas inicio y fin
    fechaDesde: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    fechaHasta: moment().format("YYYY-MM-DD"),

    //  numero de pagina y total de registros
    page: 1,
    total: 0,
    visible: 7,

    // numero de registro por pagina
    rowsPerPage: 50,

    //loading en peticiones
    cargando: false,

    //errores de api
    errored: false,
    error: false,

    //  filtros
    concepto: "",
    referencia: "",
    medio_pago: 0,
    medios_pagos: [],
  },

  mutations: {
    // mutations movimientos
    setMovimientos(state, movimientos) {
      state.movimientos = movimientos;
    },

    setMovimientosCsv(state, movimientos) {
      state.movimientosCsv = movimientos;
    },

    setConcepto(state, value) {
      state.concepto = value;
    },

    setReferencia(state, value) {
      state.referencia = value;
    },

    setMedioPago(state, value) {
      state.medio_pago = value;
    },

    setPaginate(state, value) {
      state.page = value;
    },

    setTotal(state, value) {
      state.total = value;
    },
    setrowsPerPage(state, value) {
      state.rowsPerPage = value;
    },
    setCargando(state, value) {
      state.cargando = value;
    },
    setErrord(state, value) {
      state.errored = value;
    },

    setFechaInicio(state, value) {
      state.fechaDesde = value;
    },

    setFechaFin(state, value) {
      state.fechaHasta = value;
    },

    setMediosPagos(state, medios_pagos) {
      state.medios_pagos = medios_pagos;
    },
  },

  actions: {
    //exportCsv es parametro booleano que recibe en los casos en que el usuario desea exportar los datos a un arhivo CSV
    async getMovimientos({ commit, rootState, state }, exportCsv) {
      const idcuenta = rootState.cuenta_ID;
      const cuenta_id_segun_tipo = rootState.cuenta_ID;
      const number = state.page;
      const size = state.rowsPerPage;
      const fechaI = moment(state.fechaDesde).format();
      const fechaH = moment(state.fechaHasta).format();
      const Concepto = state.concepto;
      const ReferenciaPago = state.referencia;
      const medioPagoId = state.medio_pago;

      var data = new FormData();
      data.append("number", number);
      !exportCsv && data.append("size", size);
      data.append("FechaInicio", fechaI);
      data.append("FechaFin", fechaH);
      data.append("CuentaId", idcuenta);
      // data.append("CuentaIdSegunTipo", cuenta_id_segun_tipo);
      data.append("Concepto", Concepto);
      data.append("ReferenciaPago", ReferenciaPago);
      data.append("MedioPagoId", medioPagoId);
      data.append("AcumularPorPagoIntentos", false);

      // cuentasCliente.forEach((c) => {
      //   if (c.split_cuentas && c.id === idcuenta) {
      //     data.append("CuentaIdSegunTipo", cuenta_id_segun_tipo);
      //     data.append("CargarMovimientoSubcuenta", true);
      //   }
      // });

      const cuentasCliente = rootState.CLIENTE_CUENTA_DATA.cuenta;

      const currentAccount = cuentasCliente.find(
        (c) => c.id == rootState.cuenta_ID
      );

      if (currentAccount.costo_servicio) {
        data.append("CargarMovimientoCostoServicio", true);
      }

      data.append("CuentaIdSegunTipo", currentAccount.id);
      if (currentAccount.aplica_costo_servicio) {
        data.append("AplicaCostoServicio", true);
      } else if (currentAccount.aplica_porcentaje) {
        //Si aplica porcentaje
        data.append("AplicaPorcentaje", true);
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      commit("setErrord", false);
      commit("setCargando", true);
      await Movimientos.getMovimientos(data, config)
        .then((response) => {
          // si la cuenta no contiene movimientos se actualiza store a valor vacio y efecto cargando en false
          if (response.data.data == null) {
            commit("setMovimientos", []);
            commit("setPaginate", 1);
            commit("setTotal", 0);
            commit("setCargando", false);
          } else {
            // si la consulta es distinto de null se actualiza el store mutations movimientos
            if (exportCsv) {
              commit("setMovimientosCsv", response.data.data);
            } else {
              commit("setMovimientos", response.data.data);
              commit("setPaginate", response.data.meta.page.currentPage);
              commit("setTotal", response.data.meta.page.lastPage);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            var data = error.response.status;
            if (data == 403) {
              async () => {
                try {
                  await Refresh.getRefreshToken().then(() => {
                    //
                  });
                } catch (error) {
                  //
                }
              };
            }
          } else {
            commit("setErrord", true);
          }
        })
        .finally(() => commit("setCargando", false));
    }, // Fin de getMovimientos

    // cargar los canales de pagos filter datatable
    async getChannels({ commit }) {
      const params = {
        Number: 1,
        Size: 20,
      };
      await Channels.getChannels(params)
        .then((response) => {
          commit("setMediosPagos", response.data.data);
        })
        .catch((error) => {
          var data = error.response.status;
          // alert(data);
        });
    }, // Fin de getChannels
  }, // Fin de actions

  getters: {
    fechaDesde: (state) => state.fechaDesde,
    fechaHasta: (state) => state.fechaHasta,
    pages: (state) => state.page,
    canalespago(state) {
      state.medios_pagos.push({ Id: 0, Nombre: "TODOS" });
      return state.medios_pagos;
    },
  },

  modules: {},
};
