<template>
  <v-app>
    <v-main>
      <v-dialog v-model="dialogCliente" scrollable max-width="400px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="secondary"
            @click="obtenerClientes()"
          >
            Cliente: {{ getCurrentClientName }}
            <v-icon color="purple lighten-5">south</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h6 justify-center"
            >Seleccione un Cliente
          </v-card-title>
          <v-divider></v-divider>
          <div>
            <v-text-field
              v-model="searchCli"
              append-icon="mdi-magnify"
              class="pt-3 px-3"
              label="Buscar"
              clearable
              solo
              hide-details
              dense
              @click:clear="limpiarBusqueda"
            ></v-text-field>
          </div>
          <v-card-text style="height: 250px">
            <v-radio-group v-model="id_radio_clientes" column>
              <template v-if="filteredClientes.length > 0">
                <!-- Lista de clientes filtrados -->
                <v-radio
                  class="pb-5"
                  v-for="dat in filteredClientes"
                  :key="dat.id"
                  :label="dat.cliente"
                  :value="dat.id"
                ></v-radio>
              </template>
              <template v-else>
                <!-- Mensaje cuando no hay coincidencias -->
                <div>
                  <p class="text-gray">No se encontró cliente</p>
                </div>
              </template>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="blue darken-1" text @click="cerrar"> Cerrar </v-btn>
            <v-btn color="blue darken-1" text @click="getActionStateChange()">
              seleccionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div v-if="procesando_datos">
        <loader
          object="#4caf50"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="40"
          disableScrolling="false"
          name="box"
        ></loader>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { Clientes } from "@/services/clientes.js";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ChangeCliente",
  data() {
    return {
      id_radio_clientes: 0,
      clientes: [],
      dialogCliente: false,
      procesando_datos: false,
      searchCli: "",
    };
  },
  created() {
    this.obtenerClientes();
    this.id_radio_clientes = this.getCurrentClientId;
  },
  computed: {
    ...mapGetters(["getCurrentClientName", "getCurrentClientId"]),
    filteredClientes() {
      return this.searchCli
        ? this.clientes?.filter((cliente) =>
            cliente?.cliente
              ?.toLowerCase()
              .includes(this.searchCli?.toLowerCase())
          )
        : this.clientes;
    },
  },
  methods: {
    ...mapActions(["getStateChange"]),
    limpiarBusqueda() {
      this.searchCli = ""; // Borra el término de búsqueda
    },

    getActionStateChange() {
      if (this.id_radio_clientes != "") {
        const self = this;
        var id_cliente = this.id_radio_clientes;
        // llamar la accion que cambiar las variables del State
        this.procesando_datos = true;
        this.getStateChange(id_cliente).then(function() {
          // self.$store.dispatch("getAccountChange");
          self.procesando_datos = false;
        });
        this.cerrar();
      } else {
        this.$toastr.e("Debe seleccionar un Cliente");
      }
    },
    obtenerClientes() {
      const params = {
        CargarImpuestos: false,
        CargarCuentas: true,
        CargarRubros: true,
        // CargarCuentasSubcuenta: true,
      };

      Clientes.getClientes(params)
        .then((response) => {
          if (response.data.data == null) {
            this.$toastr.e("No existen clientes");
          } else {
            this.clientes = response.data.data;
          }
        })
        .catch((error) => {
          // El response que viene puede ser undefined
          if (error.response) {
            var data = error.response.data;
            this.error = true;
            this.msg = data.message;
            this.$toastr.e(this.msg);
          } else {
            this.error = true;
            this.msg = "Error al comunicarse con el servidor de autenticación";
            this.$toastr.e(this.msg);
          }
        });
    },

    cerrar() {
      this.dialogCliente = false;
      this.limpiarBusqueda();
    },
  },
};
</script>
<style scoped>
.v-application .pink--text {
  color: #76c767 !important;
  caret-color: #824a5d !important;
}

.theme--light.v-application {
  background: rgb(204 204 204 / 4%);
  color: rgba(0, 0, 0, 0.87);
}
</style>
